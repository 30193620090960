import { Box, Divider, Flex, Grid, Group, Input, Loader, Paper, SimpleGrid, Text } from '@mantine/core';
import { StatsFirst } from '../../dasboard/components/StatsFirst';
import CoachsKpisStats from './CoachsKpisStats';
import { useGetCoachsMetricsQuery } from '../../../redux/features/coachApi';
import { IconDiscount } from '@tabler/icons-react';
import {
  IconUserPlus,
  IconDiscount2,
  IconReceipt2,
  IconCoin,
  IconArrowUpRight,
  IconArrowDownRight,
} from '@tabler/icons-react';
import { IconEqual } from '@tabler/icons-react';
import CoachMetricsTable from './CoachMetrics';
import { useState } from 'react';

const icons = {
  user: "IconUserPlus",
  discount: "IconDiscount2",
  receipt: "IconReceipt2",
  coin: "IconCoin",
};

export default function CoachSuiviPage() {
  const [query, setQuery] = useState("");

  const {data:metrics,isLoading} = useGetCoachsMetricsQuery()

  return (
    <div >
      <Box my={15}>
        <Text size="xl">Metrics globales des coachs du mois encours par rapport au precedent</Text>
        <CoachsKpisStats />
      </Box>
      <Divider  my={20}/>
      <Box>
        <Text size="xl">Metrics des coachs du mois de {new Date().toLocaleDateString('fr',{month:'long'})} par rapport au precedent</Text>
      <Divider  my={20}/>
        {
          isLoading? <Loader /> :
          (
            <>
            <CoachMetricsTable />
              {/* 
                <Grid justify="space-between" align="stretch">
                  {
                    metrics?.data?.filter(data => data?.coach?.full_name?.toLowerCase().includes(query))
                    .map((item) => {
                      const Icon = icons[item?.icon];
                      
                      return (
                        <Grid.Col span={4}>
                            <Paper withBorder p="md" radius="md" key={item.title}>
                            <Group justify="space-between">
                              <Text size="md"  >
                                {item.coach.full_name} : {item.coach.phone}
                              </Text>
                            </Group>
                            {
                              item.stats.map(data=>{
                                const rate = data.count > data.previous
                                ? 'increase'
                                : data.count < data.previous
                                ? 'decrease': 'equal'

                                const DiffIcon = rate ==="increase" 
                                ? IconArrowUpRight
                                : rate==="decrease" 
                                ? IconArrowDownRight :IconEqual

                                return (
                                  <Group align="flex-end" gap="xs" mt={25}>
                                    <Text>{data.title} : </Text>
                                    <Text >{data.count}</Text>
                                    <Text c={rate ==="increase" ? 'teal' : rate==="decrease" ? 'red': 'blue'} fz="sm" fw={500} >
                                      <span>{data.growth_rate}% </span>
                                      <DiffIcon size="1rem" stroke={1.5} />
                                      <span>preced : {data.previous}</span>
                                    </Text>
                                  </Group>
                                )
                              })
                            }
                          </Paper>
                        </Grid.Col>
                      );
                    })
                    
                  }
                </Grid> 
              */}

            </>
          )
        }
      </Box>
    </div>
  );
}
// export default CoachSuiviPage;
