// import {
//   AUTH_PATH,
//   REGISTER_PATH,
//   UPDATE_PASSWORD_PATH,
// } from "../../routes/api.paths";
import { ApiMananger } from "../apiMananger";

const userApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    loginUser: build.mutation({
      query({ body }) {
        return {
          url: "users/login/",
          method: "POST",
          headers: {
            // Accept: "application/json",
            // "Content-Type": "application/json",
            // "X-CSRFToken": token,
            // Referer: "https://genimi-admin.bakeli.tech/",
            // "Referrer-Policy": "strict-origin-when-cross-origin",
          },
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    // registerUser: build.mutation({
    //   query({ body, token }) {
    //     return {
    //       url: REGISTER_PATH,
    //       method: "POST",
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //         "X-CSRFToken": token,
    //         // Referer: "https://genimi-admin.bakeli.tech/",
    //         // "Referrer-Policy": "strict-origin-when-cross-origin",
    //       },
    //       body,
    //     };
    //   },
    //   invalidatesTags: ["Users"],
    // }),
    // updatePassword: build.mutation({
    //   query({ body, token }) {
    //     return {
    //       url: UPDATE_PASSWORD_PATH,
    //       method: "PUT",
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //         "X-CSRFToken": token,
    //         // Referer: "https://genimi-admin.bakeli.tech/",
    //         // "Referrer-Policy": "strict-origin-when-cross-origin",
    //       },
    //       body,
    //     };
    //   },
    //   invalidatesTags: ["Users"],
    // }),
  }),
});

export const {
  useLoginUserMutation,
  useRegisterUserMutation,
  useUpdatePasswordMutation,
} = userApi;
