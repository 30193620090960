import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetEpreuveQuery } from "../../../redux/features/epreuveApi";
import { ActionIcon, Box, Center, Loader } from "@mantine/core";
import { ArrowLeft } from "tabler-icons-react";
import EpreuveOrganisation from "./types/EpreuveOrganisation";
import EperuveNullType from "./types/NullType";
import EpreuveAlphabet from "./types/EpreuveAlphabet";
import EpreuveDictee from "./types/EpreuveDicte";
import EpreuveNombreManquant from "./types/EpreuveNombreManquant";
import EpreuveTypeOCandOD from "./types/EpreuveTypeOC&OD";
import EpreuveFigure from "./types/EpreuveFigure";
import EpreuveTypeTSQ from "./types/EpreuveTypeTSQ";
import EpreuveTypeOperation from "./types/EpreuveTypeOperation";
import EpreuveTypeNumberDictation from "./types/EpreuveTypeNumberDictation";

const EpreuveDetailPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { data: epreuve, isLoading } = useGetEpreuveQuery(state?.id);
    const type = epreuve?.data?.type;

    console.log("type", type);

    // Fonction pour rendre le bon composant en fonction du type
    const renderEpreuve = () => {
        switch (type) {
            case "missingNumbers":
            case "numberToWords":
            case "wordToNumbers":
                return <EpreuveNombreManquant epreuve={epreuve?.data} />;
            case "operation":
                return <EpreuveTypeOperation epreuve={epreuve?.data} />;
            case "numberDictation":
                return <EpreuveTypeNumberDictation epreuve={epreuve?.data} />;
            case "organisation":
            case "lettreManquante":
                return <EpreuveOrganisation epreuve={epreuve?.data} />;
            case "figure":
                return <EpreuveFigure epreuve={epreuve?.data} />;
            case "alphabet":
                return <EpreuveAlphabet epreuve={epreuve?.data} />;
            case "od":
            case "oc":
                return <EpreuveTypeOCandOD epreuve={epreuve?.data} />;
            case "dictee":
            case "transcription":
            case "dicteeChronometree":
                return <EpreuveDictee epreuve={epreuve?.data} />;
            case "tsq":
                return <EpreuveTypeTSQ epreuve={epreuve?.data} />;
            // case "operation":
            //     return <h1>Type operation  {console.log(epreuve?.data)}</h1>;

            case null:
                return <EperuveNullType epreuve={epreuve?.data} />;
            default:
                return (
                    <h1>
                        Détails pour le type {type} d'exercice en cours{" "}
                        {console.log(epreuve?.data)}
                    </h1>
                );
        }
    };

    return (
        <Box>
            <ActionIcon onClick={() => navigate(-1)}>
                <ArrowLeft />
            </ActionIcon>
            {isLoading ? (
                <Center>
                    <Loader />
                </Center>
            ) : (
                <Box>{renderEpreuve()}</Box>
            )}
        </Box>
    );
};

export default EpreuveDetailPage;
