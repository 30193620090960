import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderPage from "../../../components/HeaderPage";
import { useEditEleveClasseMutation, useGetEleveQuery } from "../../../redux/features/eleveApi";
import {
  ActionIcon,
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Group,
  Loader,
  Modal,
  Paper,
  Select,
  SimpleGrid,
  Table,
  Tabs,
  Text,
  TextInput,
  Timeline,
  Title,
} from "@mantine/core";
import { StatsFirst } from "../../dasboard/components/StatsFirst";
import {
  useAddClasseHistoriqueMutation,
  useGetClassesQuery,
  useGetStudentHistoriqueClassesQuery,
} from "../../../redux/features/classeApi";
import { useForm } from "@mantine/form";
import {
  useAddCompositionsMutation,
  useEditCompositionsMutation,
  useGetCompositionsByEleveQuery,
  // useEditCompositionsMutation,
} from "../../../redux/features/compostionApi";
// import EditAction from "../actions/EditAction";
import { Edit, Eye, X } from "tabler-icons-react";
import { useGetStudentAppelsQuery } from "../../../redux/features/appelApi";
import { formateDate } from "../../../utils/filterDates";
import toast from "react-hot-toast";
import {
  useGetDiagnostiqueTentavivesByElevesGroupeByTententativeQuery,
  useGetDiagnostiqueTentavivesByElevesQuery,
} from "../../../redux/features/diagnostiqueApi";
import { useDisclosure } from "@mantine/hooks";
import EditAction from "../actions/EditAction";
import AddAction from "../actions/AddAction";

function EleveDetailsPage() {
  const [opened, { open, close }] = useDisclosure(false);
  const params = useParams();
  const navigation = useNavigate();
  const location = useLocation();
  
  const { data, isLoading } = useGetEleveQuery(parseInt(params.id));
  const { data: appels, isLoading: appelIsLoading } = useGetStudentAppelsQuery(
    parseInt(params.id),
    { skip: !params?.id }
  );

  const { data: classeHistoriqueData, isLoading: isClasseHistoriqueLoading } =
    useGetStudentHistoriqueClassesQuery(
      {
        studentId: parseInt(params.id),
      },
      { skip: !params?.id }
    );

  const [activeTab, setActiveTab] = useState("");

  const { data: compositions, isLoading: isCompositionLoading } =
    useGetCompositionsByEleveQuery(parseInt(params.id), { skip: !params.id });

  const { data: classes } = useGetClassesQuery();
  const [addComposition, { isLoading: addCompoLoading }] =
    useAddCompositionsMutation();
  const [editeComposition] = useEditCompositionsMutation();

  const { data: diagnostiqueEleveData, isLoading: isDiagnosticLoading } =
    useGetDiagnostiqueTentavivesByElevesQuery(params?.id, {
      skip: !params?.id,
    });

  const { data: tentativeData, isLoading: istentativeLoading } =
    useGetDiagnostiqueTentavivesByElevesGroupeByTententativeQuery(
      {
        eleveId: parseInt(params?.id),
        diagnosticId: activeTab,
      },
      { skip: !activeTab && !params?.id }
    );

  const [itemEdited, setItemEdited] = useState(null);
  const [addCompo, setAddCompo] = useState(false);
  const [newClasse, setNewClasse] = useState({classeId:"",action:"Passage de classe"})
  const [editEleveClasse, { isLoading: editClasseLoading }] = useEditEleveClasseMutation();
  const [addClassesHistorique] = useAddClasseHistoriqueMutation();


  const form = useForm({
    initialValues: {
      composition: "",
      moyenne: "",
      classeId: location?.state?.classeId ?? "",
      rang: null,
      studentId: parseInt(params?.id),
      date_composition: `${new Date(Date.now()).getFullYear()}-${(
        new Date(Date.now()).getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${new Date(Date.now())
        .getDate()
        .toString()
        .padStart(2, "0")}`,
    },

    validate: {
      composition: (value) =>
        value === "" ? "Ce champ est obligatoire" : null,
      moyenne: (value) => (value === "" ? "Ce champ est obligatoire" : null),
      classeId: (value) => (value === "" ? "Ce champ est obligatoire" : null),
    },
  });

  const handleAddComposition = async (values) => {
    
    console.log({ values });
    const { rang, ...rest } = values;
    const formatValues = rang ? { rang, ...rest } : { ...rest };
    // return console.log({
    //   data:
    //     data?.niveaux.length > 0
    //       ? data?.niveaux?.map((niveau) => niveau?.name)?.join(" ET ")
    //       : "Pase de niveaux",
    // });
    try {
      if (itemEdited) {
        const res = await editeComposition({
          body: {
            ...formatValues,
            id: itemEdited
          },
        });
        // console.log({ res });
        if (res?.error) {
          if (res?.error?.status === 409) {
            // console.log({ error409: res?.error });
            return toast.error(res?.error?.data?.message);
          }
          return toast.error("Une erreur c'est produite");
        }
        toast.success("Modification reussi.");
        setItemEdited(null);
      } else {
          values.niveau = data?.niveaux.length > 0
            ? data?.niveaux?.map((niveau) => niveau?.name)?.join(" ET ")
            : "Pase de niveaux";
        const res = await addComposition({ body: values });
        // console.log({ res });
        if (res?.error) {
          if (res?.error?.status === 409) {
            // console.log({ error409: res?.error });
            return toast.error(res?.error?.data?.message);
          }
          return toast.error("Une erreur c'est produite");
        }
        toast.success("Ajout reussi.");
        setAddCompo(false);
      }
      form.reset();
    } catch (error) {
      toast.error("Une erreur c'est produite");
    }
  };

  const handleChangeClasse = async(e) =>{
    e.preventDefault();
    const body = {id:parseInt(params?.id),classeId:newClasse?.classeId}
    const res = await EditAction(editEleveClasse, body);
    if(res?.data?.status===200){
      toast.success("Modification de la classe reussie")
      close();
      await AddAction(addClassesHistorique,{...newClasse,studentId:params?.id})
      return
    }
    return toast.error("Un probleme est surveneu veuillez contacter l'equipe technique")
  }

  const compotionForm = (
    <form onSubmit={form.onSubmit(handleAddComposition)}>
      <Group position="apart">
        <Group noWrap>
          <Select
            label={"Composition"}
            data={[
              { value: "COMPOSITION1", label: "COMPOSITION1" },
              { value: "COMPOSITION2", label: "COMPOSITION2" },
              { value: "COMPOSTION3", label: "COMPOSITION3" },
              { value: "REDOUBLE", label: "REDOUBLE" },
            ]}
            placeholder={"Selectionner une composition"}
            {...form.getInputProps("composition")}
          />
          <Select
            label={"Classe"}
            data={
              classes?.data.map((classe) => ({
                value: classe?.id,
                label: classe?.name,
              })) ?? []
            }
            placeholder={"Selectionner une classe"}
            {...form.getInputProps("classeId")}
            defaultValue={data?.classe?.id}
          />
          <TextInput
            type="date"
            label={"Date"}
            placeholder={"date de la composition"}
            {...form.getInputProps("date_composition")}
          />
          <TextInput
            type="number"
            label={"Moyenne"}
            placeholder={"moyenne"}
            {...form.getInputProps("moyenne")}
          />
          <TextInput
            type="number"
            label={"Rang"}
            placeholder={"rang de l'eleve"}
            {...form.getInputProps("rang")}
          />
        </Group>

        <Group mt={20}>
          <Button
            size="sm"
            color="green"
            type="submit"
            loading={addCompoLoading}
            radius={10}
          >
            Valider
          </Button>
          <ActionIcon
            onClick={() => {
              setAddCompo(false);
              setItemEdited(null);
              form.reset();
            }}
          >
            <X />
          </ActionIcon>
        </Group>
      </Group>
    </form>
  );

  return (
    <div>
      <HeaderPage
        navigation={navigation}
        title={`Detailes eleve : ${
          data ? data?.firstName + " " + data?.lastName : "..."
        }`}
      />

      <Divider my={20} />

      {isLoading ? (
        <Loader />
      ) : (
        <Grid>
          {/* Informations Eleve */}
          <Grid.Col lg={3} md={4}>
            <Paper withBorder p={20} mb={20} radius={10}>
              <Group position="center">
                <Avatar
                  size={150}
                  radius={"50%"}
                  color={"red"}
                  mt={10}
                  src={
                    "https://img.freepik.com/free-icon/student_318-201553.jpg"
                  }
                >
                  AB
                </Avatar>
                <Title order={3} mt={5}>
                  {data?.firstName} {data?.lastName}
                </Title>
              </Group>
            </Paper>
            <Paper withBorder p={20} mb={20} radius={10}>
              <Group spacing={20} mb={10}>
                <Text>Ecole :</Text>
                <Text weight={600}>{data?.school?.name}</Text>
              </Group>{" "}
              <Group spacing={20} mb={10}>
                <Text>Classe :</Text>
                <Text weight={600}>{data?.classe?.name}</Text>
                <ActionIcon
                onClick={open}
                >
                  <Edit color="orange"/>
                </ActionIcon>

              </Group>
              <Group spacing={20} mb={10}>
                <Text>Boursier :</Text>
                <Text weight={600}>{data?.boursier}</Text>
              </Group>
              <Group spacing={20} mb={10}>
                <Text>Inscrit :</Text>
                <Text weight={600}>{data?.isInscrit ? "OUI" : "NON"}</Text>
              </Group>
              <Group spacing={20} mb={10}>
                <Text>Professeurs :</Text>
                <Group>
                  {data?.coachs.length === 0 ? (
                    <Text weight={600}>Pas de Professeurs</Text>
                  ) : (
                    data?.coachs.map((item, index) => (
                      <Badge key={index}>{item?.fullName}</Badge>
                    ))
                  )}
                </Group>
              </Group>
              <Group spacing={20} mb={10}>
                <Text>Niveaux :</Text>
                <Group>
                  {data?.niveaux.map((item, index) => (
                    <Badge key={index}>{item?.name}</Badge>
                  ))}
                </Group>
              </Group>
              {/* Iformations Parent et Parain */}
              <Paper withBorder p={20} mt={20} radius={10}>
                <Timeline color="gray" active={1} lineWidth={1} bulletSize={12}>
                  <Timeline.Item title="Parent">
                    {!data?.parent?.id ? (
                      <Text size="xs" mt={4} color="gray">
                        Pas de parent
                      </Text>
                    ) : (
                      <Box>
                        <Text size="xs" mt={4}>
                          {data?.parent?.fullName}
                        </Text>
                        <Text size="xs" mt={4}>
                          {data?.parent?.phone}
                        </Text>
                        <Text size="xs" mt={4}>
                          {data?.parent?.adresse}
                        </Text>
                      </Box>
                    )}
                  </Timeline.Item>

                  <Timeline.Item title="Parrain">
                    {!data?.parrain?.id ? (
                      <Text size="xs" mt={4} color="gray">
                        Pas de parrain
                      </Text>
                    ) : (
                      <Box>
                        <Text size="xs" mt={4}>
                          {data?.parrain?.fullName}
                        </Text>
                        <Text size="xs" mt={4}>
                          {data?.parrain?.phone}
                        </Text>
                        <Text size="xs" mt={4}>
                          {data?.parrain?.adresse}
                        </Text>
                      </Box>
                    )}
                  </Timeline.Item>
                </Timeline>
              </Paper>
            </Paper>
          </Grid.Col>

          <Grid.Col lg={9} md={8}>
            {/* Appel Statistiques */}
            <Paper mb={20} withBorder radius={10}>
              <Box p={20}>
                <Title>{data?.pointages?.appels}</Title>
                <Title weight={500} order={3}>
                  Appels
                </Title>
              </Box>
              <StatsFirst
                data={[
                  {
                    title: "Presences",
                    stats: data?.pointages?.presences,
                  },
                  {
                    title: "Absences",
                    stats: data?.pointages?.absences,
                  },
                  {
                    title: "Retards",
                    stats: data?.pointages?.retards,
                  },
                ]}
              />
            </Paper>

            {/* Liste des appels */}
            <Paper withBorder p={20} mb={20} radius={10}>
              Liste des appels
              <Box>
                <Flex justify="space-between">
                  <Text>Dates</Text>
                  <Text>Status</Text>
                  <Text>Coach</Text>
                </Flex>
                {appelIsLoading ? (
                  <Loader />
                ) : (
                  appels?.map((item, index) => (
                    <Flex justify="space-between" key={index}>
                      <Text>{formateDate(item?.date_appel)}</Text>
                      <Text>{item?.status}</Text>
                      <Text>{item?.coach?.fullName}</Text>
                    </Flex>
                  ))
                )}
              </Box>
            </Paper>

            {/* La liste des diagnostiques */}
            <Paper withBorder p={20} mb={20} radius={10}>
              <Text>Liste des diagnostics</Text>
              {isDiagnosticLoading ? (
                "Chargement"
              ) : diagnostiqueEleveData?.diagnostics &&
                diagnostiqueEleveData?.diagnostics?.length > 0 ? (
                <Tabs
                  value={
                    diagnostiqueEleveData
                      ? diagnostiqueEleveData?.diagnostics[0]?.id.toString()
                      : activeTab
                  }
                  mt={10}
                >
                  <Tabs.List>
                    {diagnostiqueEleveData?.diagnostics &&
                      diagnostiqueEleveData?.diagnostics.map(
                        (diagnostic, index) => (
                          <Tabs.Tab
                            key={index}
                            value={diagnostic?.id.toString()}
                            onClick={() =>
                              setActiveTab(diagnostic?.id.toString())
                            }
                          >
                            {diagnostic?.diagnostic}
                          </Tabs.Tab>
                        )
                      )}
                  </Tabs.List>

                  {diagnostiqueEleveData?.diagnostics.map(
                    (diagnostic, index) => (
                      <Tabs.Panel
                        key={index}
                        value={diagnostic?.id.toString()}
                        // style={{ padding: 20 }}
                        py={20}
                      >
                        <Table color="gray" lineWidth={2} bulletSize={15}>
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Niveau 1</th>
                              <th>Niveau 2</th>
                              <th>Niveau 3</th>
                              <th>actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {istentativeLoading
                              ? "Chargement en cours..."
                              : tentativeData?.data
                              ? tentativeData?.data.map((tentative, index) => (
                                  <tr key={tentative?.tentative}>
                                    <td>
                                      {new Date(
                                        tentative?.date_diagnostic
                                      ).toLocaleDateString("fr-FR", {
                                        day: "2-digit",
                                        month: "long",
                                        year: "numeric",
                                      })}
                                    </td>
                                    <td>
                                      {tentative?.notes["1"]?.data.length}
                                    </td>
                                    <td>
                                      {tentative?.notes["2"]?.data.length}
                                    </td>
                                    <td>
                                      {tentative?.notes["3"]?.data.length}
                                    </td>
                                    <td className="flex items-center justify-end">
                                      <ActionIcon
                                        onClick={() =>
                                          navigation("notes", {
                                            state: {
                                              diagnosticId: diagnostic?.id,
                                              key: index,
                                              date: new Date(
                                                tentative?.date_diagnostic
                                              ).toLocaleDateString("fr-FR", {
                                                day: "2-digit",
                                                month: "long",
                                                year: "numeric",
                                              }),
                                            },
                                          })
                                        }
                                      >
                                        <Eye size={18} />
                                      </ActionIcon>
                                    </td>

                                    {/* <Timeline
                                      color="gray"
                                      lineWidth={2}
                                      bulletSize={15}
                                      mt={10}
                                    >
                                      <Timeline.Item title={"Niveau 1"}>
                                        {tentative?.notes["1"]?.data.length ===
                                        0
                                          ? "Pas d'exercices"
                                          : tentative?.notes["1"]?.data.map(
                                              (note, index) => (
                                                <Flex
                                                  align={"center"}
                                                  wrap={"nowrap"}
                                                  gap={10}
                                                  key={index}
                                                  mt={5}
                                                >
                                                  <Paper
                                                    withBorder
                                                    display={"flex"}
                                                    py={5}
                                                    px={10}
                                                    style={{
                                                      justifyContent:
                                                        "space-between",
                                                      flex: 1,
                                                    }}
                                                  >
                                                    <Text size={"sm"}>
                                                      {new Date(
                                                        note?.created_at
                                                      ).toLocaleDateString(
                                                        "fr-FR",
                                                        {
                                                          day: "2-digit",
                                                          month: "long",
                                                          year: "numeric",
                                                        }
                                                      )}
                                                    </Text>
                                                    <Text
                                                      size={"md"}
                                                      weight={700}
                                                    >
                                                      Note: {note?.note} /{" "}
                                                      {note?.bareme}
                                                    </Text>
                                                  </Paper>
                                                </Flex>
                                              )
                                            )}
                                      </Timeline.Item>
                                    </Timeline> */}
                                  </tr>
                                ))
                              : null}
                          </tbody>
                        </Table>
                        {/* <Timeline color="gray" lineWidth={2} bulletSize={15}>
                          {istentativeLoading
                            ? null
                            : tentativeData?.data
                            ? tentativeData?.data.map((tentative) => (
                                <Timeline.Item
                                  title={new Date(
                                    tentative?.date_diagnostic
                                  ).toLocaleDateString("fr-FR", {
                                    day: "2-digit",
                                    month: "long",
                                    year: "numeric",
                                  })}
                                  key={tentative?.tentative}
                                >
                                  <Timeline
                                    color="gray"
                                    lineWidth={2}
                                    bulletSize={15}
                                    mt={10}
                                  >
                                    <Timeline.Item title={"Niveau 1"}>
                                      {tentative?.notes["1"]?.data.length === 0
                                        ? "Pas d'exercices"
                                        : tentative?.notes["1"]?.data.map(
                                            (note, index) => (
                                              <Flex
                                                align={"center"}
                                                wrap={"nowrap"}
                                                gap={10}
                                                key={index}
                                                mt={5}
                                              >
                                                <Paper
                                                  withBorder
                                                  display={"flex"}
                                                  py={5}
                                                  px={10}
                                                  style={{
                                                    justifyContent:
                                                      "space-between",
                                                    flex: 1,
                                                  }}
                                                >
                                                  <Text size={"sm"}>
                                                    {new Date(
                                                      note?.created_at
                                                    ).toLocaleDateString(
                                                      "fr-FR",
                                                      {
                                                        day: "2-digit",
                                                        month: "long",
                                                        year: "numeric",
                                                      }
                                                    )}
                                                  </Text>
                                                  <Text
                                                    size={"md"}
                                                    weight={700}
                                                  >
                                                    Note: {note?.note} /{" "}
                                                    {note?.bareme}
                                                  </Text>
                                                </Paper>
                                              </Flex>
                                            )
                                          )}
                                    </Timeline.Item>
                                    <Timeline.Item title={"Niveau 2"}>
                                      {tentative?.notes["2"]?.data.length === 0
                                        ? "Pas d'exercices"
                                        : tentative?.notes["2"]?.data.map(
                                            (note, index) => (
                                              <Flex
                                                align={"center"}
                                                wrap={"nowrap"}
                                                gap={10}
                                                key={index}
                                                mt={5}
                                              >
                                                <Paper
                                                  withBorder
                                                  display={"flex"}
                                                  py={5}
                                                  px={10}
                                                  style={{
                                                    justifyContent:
                                                      "space-between",
                                                    flex: 1,
                                                  }}
                                                >
                                                  <Text size={"sm"}>
                                                    {new Date(
                                                      note?.created_at
                                                    ).toLocaleDateString(
                                                      "fr-FR",
                                                      {
                                                        day: "2-digit",
                                                        month: "long",
                                                        year: "numeric",
                                                      }
                                                    )}
                                                  </Text>
                                                  <Text
                                                    size={"md"}
                                                    weight={700}
                                                  >
                                                    Note: {note?.note} /{" "}
                                                    {note?.bareme}
                                                  </Text>
                                                </Paper>
                                              </Flex>
                                            )
                                          )}
                                    </Timeline.Item>
                                    <Timeline.Item title={"Niveau 3"}>
                                      {tentative?.notes["3"]?.data.length === 0
                                        ? "Pas d'exercices"
                                        : tentative?.notes["3"]?.data.map(
                                            (note, index) => (
                                              <Flex
                                                align={"center"}
                                                wrap={"nowrap"}
                                                gap={10}
                                                key={index}
                                                mt={5}
                                              >
                                                <Paper
                                                  withBorder
                                                  display={"flex"}
                                                  py={5}
                                                  px={10}
                                                  style={{
                                                    justifyContent:
                                                      "space-between",
                                                    flex: 1,
                                                  }}
                                                >
                                                  <Text size={"sm"}>
                                                    {new Date(
                                                      note?.created_at
                                                    ).toLocaleDateString(
                                                      "fr-FR",
                                                      {
                                                        day: "2-digit",
                                                        month: "long",
                                                        year: "numeric",
                                                      }
                                                    )}
                                                  </Text>
                                                  <Text
                                                    size={"md"}
                                                    weight={700}
                                                  >
                                                    Note: {note?.note} /{" "}
                                                    {note?.bareme}
                                                  </Text>
                                                </Paper>
                                              </Flex>
                                            )
                                          )}
                                    </Timeline.Item>
                                  </Timeline>
                                </Timeline.Item>
                              ))
                            : null}
                        </Timeline> */}
                      </Tabs.Panel>
                    )
                  )}
                </Tabs>
              ) : (
                "Pas de diagnostic"
              )}
            </Paper>

            {/* Compositions */}
            <Paper withBorder p={20} mb={20} radius={10}>
              <Group position="apart">
                <Text>Liste des compositions</Text>
                <Button onClick={() => setAddCompo(true)} radius={10} size="sm">
                  Ajouter une composition
                </Button>
              </Group>
              <Divider my={20} />
              {(addCompo || itemEdited) && compotionForm}
              <Box mt={20}>
                {isCompositionLoading ? (
                  "chargement des compositions"
                ) : compositions?.data?.length > 0 ? (
                  <Table>
                    <thead>
                      <tr>
                        <th>Classes</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {compositions?.data.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <Text weight={800} size={"xl"}>
                              Classe {item?.classe?.name}
                            </Text>
                          </td>
                          <td colSpan={3}>
                            <Table bg={"rgba(0,0,0,0.02)"} py={0}>
                              <tr
                                style={{ backgroundColor: "rgba(0,0,0,0.1)" }}
                              >
                                <th>Composition</th>
                                <th>Date</th>
                                <th>Rang</th>
                                <th>Moyenne</th>
                                <th>Niveaux</th>
                                <th>Actions</th>
                              </tr>
                              {item?.data?.length > 0
                                ? item?.data
                                    .toSorted(
                                      (a, b) =>
                                        a?.composition.at(-1) -
                                        b?.composition.at(-1)
                                    )
                                    .map((_item) => (
                                      <tr
                                        // title={_item?.composition}
                                        key={_item?.id}
                                      >
                                        <td>
                                          <Text size={"sm"}>
                                            {_item?.composition}
                                          </Text>
                                        </td>
                                        <td>
                                          <Text size={"sm"}>
                                            {new Date(
                                              _item?.date_composition
                                            ).toLocaleDateString("fr-FR", {
                                              day: "2-digit",
                                              month: "long",
                                              year: "numeric",
                                            })}
                                          </Text>
                                        </td>
                                        <td>
                                          {_item?.rang ? (
                                            <Text size={"md"} weight={500}>
                                              {_item?.rang}
                                            </Text>
                                          ) : (
                                            "Pas de rang"
                                          )}
                                        </td>
                                        <td>
                                          <Text size={"md"} weight={500}>
                                            {_item?.moyenne}
                                          </Text>
                                        </td>
                                        <td>
                                          <Text size={"md"} weight={500}>
                                            {_item?.niveau ? _item.niveau : "Pas de niveau"}
                                          </Text>
                                        </td>
                                        <td>
                                          <Group>
                                            {itemEdited &&
                                            itemEdited === _item?.id ? null : (
                                              <>
                                                <ActionIcon
                                                  onClick={() => {
                                                    setItemEdited(_item?.id);
                                                    form.setValues({
                                                      classeId:
                                                        item?.classe?.id,
                                                      composition:
                                                        _item?.composition,
                                                      moyenne: _item?.moyenne,
                                                      rang: _item?.rang,
                                                      date_composition:
                                                        _item?.date_composition,
                                                    });
                                                  }}
                                                >
                                                  <Edit />
                                                </ActionIcon>
                                              </>
                                            )}
                                          </Group>
                                        </td>
                                      </tr>
                                    ))
                                : null}
                            </Table>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  "Pas de composition"
                )}
              </Box>
            </Paper>

            {/* Liste des historiques */}
            <Paper withBorder p={20} mb={20} radius={10}>
              <Text mb={10}>historiques</Text>
              <Box>
                <Table>
                  <thead>
                    <tr>
                      <th>Dates</th>
                      <th>Actions</th>
                      <th>classes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isClasseHistoriqueLoading
                      ? "Chargement en cours..."
                      : classeHistoriqueData
                      ? classeHistoriqueData?.data?.map((history) => (
                          <tr key={history?.id}>
                            <td>
                              {new Date(
                                history?.created_at
                              )?.toLocaleDateString("fr-FR", {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })}
                            </td>
                            <td>{history?.action}</td>
                            <td>{history?.classe?.name}</td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </Table>
              </Box>
            </Paper>
          </Grid.Col>
        </Grid>
      )}
      <Modal opened={opened} onClose={close} title="Modifier la classe"  size={"md"}>
      {/* Modal content */}
          <form onSubmit={(e)=>handleChangeClasse(e) }>
              <Select
                className="required"
                data={classes?.data?.map((item) => ({
                  label: item?.name,
                  value: item?.id,
                })) ?? []}
                  placeholder={data?.classe?.name}
                  onChange={e => setNewClasse({...newClasse,classeId:e})}
                  searchable
              />
              <Select
                label="action de la modification"
                defaultValue={newClasse.action}
                data={['Passage de classe', 'Redoubler']}
                onChange={e => setNewClasse({...newClasse,action:e})}
              />
              <Group mt={10}>
                  <Button color={"gray"} onClick={()=>{
                  form.reset();
                  close();
                  }}>
                  Annuler
                  </Button>
                  <Button type={"submit"}>
                  {isLoading ? "Envoi...": "Modifier"}
                  </Button>
              </Group>
          </form>

    </Modal>
    </div>
  );
}

export default EleveDetailsPage;
