

export const handleArchiveEpreuve  =async (id,value,archiveEpreuve)=>{
    try {
      const body = {
        "id": id,
        "archived": !value,
      }
      const res =  await archiveEpreuve({body});
      console.log("response archive",res);
    } catch (error) {
      console.log("handleArchiveEpreuve erreur ",error);
    }
  }