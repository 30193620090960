import { Box, Text, Badge } from "@mantine/core";
import EperuveTypeHeader from "./compenents/EpreuveTypeHeader";

const EpreuveTypeNumberDictation = ({ epreuve }) => {
    return (
        <Box>
            <h1>Operation</h1>
            <Box>
                {
                    console.log(epreuve)
                }
                <EperuveTypeHeader epreuve={epreuve} />
                <Box my={10}>
                    <Text>Choix entre</Text>
                    {
                        epreuve?.choices?.join(" ").split(" ")
                        .map((choice) =>
                            <Badge 
                                color="gray" 
                                className="text-dark me-3"
                                variant="outline" 
                            >
                                {choice} 
                            </Badge>)
                    }
                </Box>
                <Box mt={15}>
                    <Text>La correction</Text>
                    {
                        epreuve?.correction_dp?.join(" ").split(" ")
                            .map((choice) =>
                                <Badge
                                    color="gray"
                                    className="text-dark me-3"
                                    variant="outline"
                                >
                                    {choice}
                                </Badge>)
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default EpreuveTypeNumberDictation;