import { Badge, Box, Text } from "@mantine/core";
import EperuveTypeHeader from "./compenents/EpreuveTypeHeader";

const EpreuveTypeTSQ = ({epreuve}) => {
    return ( 
        <Box>
            <EperuveTypeHeader epreuve={epreuve} />
            {
                console.log("epreuve", epreuve)
            }
            <Box my={15}>
                <Text>Correction Détails :</Text>
                {epreuve?.correction_dp?.map((item, index) => {
                    const parsedData = JSON.parse(item); // Parse la chaîne JSON
                    return (
                        <Box key={index} my={5}>
                            {parsedData.map((question, qIndex) => (
                                <Box key={qIndex} my={5}>
                                    <Text weight={600}>{question.titre}</Text>
                                    <Box>
                                        {question.responses.map((response, rIndex) => (
                                            <Badge
                                                key={rIndex}
                                                color={response.isCorrect ? "green" : "red"}
                                                className="text-dark me-3"
                                                variant="outline"
                                            >
                                                {response.titre || "Pas de réponse"}
                                            </Badge>
                                        ))}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    );
                })}
            </Box>;
        </Box>
    );
}
 
export default EpreuveTypeTSQ;