import { useSelector } from "react-redux";

const useCurrentUser = () => {
  const user = useSelector((state) => state.user.currentUser);
  // if (user?.status === "PARTENAIRE" || user?.status === "ADMIN") {
  //   return user;
  // }
  return user;
};

export default useCurrentUser;
