import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_API } from "../routes/api.routes";

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: DEFAULT_API,
  }),
  // refetchOnReconnect: true,
  keepUnusedDataFor: 86400,
  reducerPath: "genimiWeb",
  endpoints: () => ({}),
});

export const ApiMananger = baseApi.enhanceEndpoints({
  addTagTypes: [
    "Coachs",
    "Parents",
    "Fiches",
    "Users",
    "Eleves",
    "Appels",
    "comprehensions",
    "Paiements",
    "Evaluations",
    "Niveaux",
    "Classes",
    "Ecoles",
    "Diagnostics",
    "DiagnosticParents",
    "Villes",
    "Epreuves",
    "Notes",
    "Kpi",
    "Waves",
    "Maitres",
    "Directeurs",
    "Partenaires",
  ],
});
