import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

const parrainApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getParrains: build.query({
      query: () => API.PARRAINS_API.GETALL(),
      providesTags: ["Parrains"],
    }),
    addParrains: build.mutation({
      query({ body, token }) {
        return {
          url: API.PARRAINS_API.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Parrains"],
    }),
    EditParrains: build.mutation({
      query({ body, token }) {
        return {
          url: API.PARRAINS_API.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Parrains"],
    }),
    deleteParrains: build.mutation({
      query(id) {
        return {
          url: API.PARRAINS_API.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Parrains"],
    }),
  }),
});

export const {
  useGetParrainsQuery,
  useAddParrainsMutation,
  useEditParrainsMutation,
  useDeleteParrainsMutation,
} = parrainApi;
