import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetDiagnosticStudentsListeByDiagnosticQuery } from "../../../redux/features/noteApi";
import {
  ActionIcon,
  Box,
  Divider,
  Group,
  Loader,
  Select,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useGetEcolesQuery } from "../../../redux/features/ecoleApi";
import BulletinDiagnostiqueClasseTable from "../components/BulletinDiagnostiqueClasseTable";
import { ArrowLeft } from "tabler-icons-react";

function BulletinListByClasse() {
  const params = useParams();
  const navigation = useNavigate();

  const { data, isLoading } = useGetDiagnosticStudentsListeByDiagnosticQuery(
    parseInt(params?.id)
  );
  const { data: ecoles } = useGetEcolesQuery();

  const [sorted, setSorted] = useState();
  const [query, setQuery] = useState("");

  // console.log({ data: data?.students });

  return (
    <Box>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Group>
            <ActionIcon onClick={() => navigation(-1)}>
              <ArrowLeft />
            </ActionIcon>
            <Title order={3}>{data?.diagnostic?.title}</Title>
          </Group>
          <Divider my={20} />

          <Group position="apart" mb={20}>
            <TextInput
              placeholder="Rechercher"
              onChange={(e) => setQuery(e.target.value.toLowerCase())}
            />
            <Select
              searchable
              clearable
              data={
                ecoles?.data.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                })) ??
                [] ??
                []
              }
              placeholder="filtrer par Ecoles"
              onChange={(e) => setSorted(e)}
            />
          </Group>

          {data?.students.length === 0 ? (
            <Text>Pas de notes</Text>
          ) : (
            <BulletinDiagnostiqueClasseTable
              data={
                data?.students
                  .filter((item) => {
                    const schoolMatch = !sorted || item.schoolId === sorted;
                    return schoolMatch;
                  })
                  .filter(
                    (item) =>
                      item?.firstName
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                      item?.lastName.toLowerCase().includes(query.toLowerCase())
                  ) ?? []
              }
            />
          )}
        </>
      )}
    </Box>
  );
}

export default BulletinListByClasse;
