import React from "react";
import { useParams } from "react-router-dom";
import {
  useDesacteferCoachFichesMutation,
  useGetCoachWithFichesQuery,
} from "../../../redux/features/ficheApi";
import useTableWithPagination from "../../../layouts/table";
import toast from "react-hot-toast";
import { Avatar, Grid, Group, Paper, Text, Title } from "@mantine/core";
import { useGetEleveByCoachQuery } from "../../../redux/features/eleveApi";
import { ConnexionKpi } from "../components/ConnexionKpi";
import { useGetCoachAppelsQuery } from "../../../redux/features/coachApi";
import CoachAppelKpi from "../components/CoachAppelKpi";
import TableWithPagination from "../../../layouts/table/TableWithPagination";

function CaochDetailsPage() {
  const params = useParams();

  const [Unarchive] = useDesacteferCoachFichesMutation();
  const { data: appels, isLoadingAppel } = useGetCoachAppelsQuery({
    coachId: params?.id,
  });
  // const { data:students,isLoading:isStudentsLaoding} = useGetEleveByCoachQuery({coachId:params?.id})

  const handleDelete = async (id, item) => {
    // return console.log({
    //   item,
    // });
    try {
      const res = await Unarchive({
        coachId: parseInt(params?.id),
        ficheId: item?.fiches?.id,
      });

      //   console.log(res);
      if (!res?.error) {
        return toast.success("Fiche desarchiver");
      } else {
        return toast.error("Une error c'est produite");
      }
    } catch (error) {
      toast.error("Une error c'est produite");
    }
  };

  const { TableWithPagination: FicheTable } = useTableWithPagination({
    useQuery: useGetCoachWithFichesQuery,
    filterRequestQuery: { coachId: params?.id },
    Component: TableWithPagination,
    searchs: ["fiches.name", "fiches.objectifPedagogique"],
    componentOptions: {
      fields: [
        { label: "id", field: "id" },
        { label: "Fiches", field: "fiches.name" },
        { label: "description", field: "fiches.objectifPedagogique", take: 30 },
        { label: "Classe", field: "fiches.classe.name" },
        { label: "Niveau", field: "fiches.niveau.name" },
      ],

      handleDelete: handleDelete,
    },
  });

  const { TableWithPagination: StudentsTable } = useTableWithPagination({
    useQuery: useGetEleveByCoachQuery,
    filterRequestQuery: { coachId: params?.id },
    Component: TableWithPagination,
    searchs: ["school.name", "classe.name", "full_name"],
    componentOptions: {
      fields: [
        { label: "id", field: "id" },
        { label: "Prenom Nom", field: "full_name" },
        { label: "Ecole", field: "school.name" },
        { label: "Classe", field: "classe.name" },
      ],

      handleDelete: () => console.log("deleted test log"),
    },
  });

  const { TableWithPagination: AppelTable } = useTableWithPagination({
    useQuery: useGetCoachAppelsQuery,
    filterRequestQuery: { coachId: params?.id },
    Component: TableWithPagination,
    searchs: ["created_at__date"],
    componentOptions: {
      fields: [
        { label: "Date", field: "created_at__date" },
        { label: "Total eleves", field: "total_appels" },
        { label: "Absences", field: "pourcentage_absent" },
        { label: "Retards", field: "pourcentage_retard" },
        { label: "Presents", field: "pourcentage_present" },
      ],

      handleDelete: handleDelete,
    },
  });

  return (
    <div>
      Details coach
      <Grid>
        {/* informations concernant le coach  */}
        <Grid.Col lg={2} md={3}>
          {/* info personneles */}
          <Paper withBorder p={20} mb={20} radius={10}>
            <Group position="center">
              <Avatar
                size={100}
                radius={"50%"}
                color={"red"}
                mt={10}
                src={"https://img.freepik.com/free-icon/student_318-201553.jpg"}
              >
                AB
              </Avatar>
              <Title order={4}>
                {/* {data?.firstName} {data?.lastName} */}
                {/* firstName lastName */}
              </Title>
            </Group>
          </Paper>
        </Grid.Col>
        <Grid.Col lg={10} md={9}>
          {/* <Paper mb={15} withBorder radius={10}>
            <Box p={12}>
              <Title weight={500} order={3}>
                KPIS
              </Title>
            </Box>
            <StatsFirst
              data={[
                {
                  title: "Label",
                  stats: "?",
                },
                {
                  title: "Label",
                  stats: "?",
                },
                {
                  title: "Label",
                  stats: "?",
                },
              ]}
            />
          </Paper> */}
        </Grid.Col>
        <Grid.Col>
          <Paper withBorder p={20} mb={20} radius={10}>
            <Text py={5}>Liste des appels </Text>
            {/* <AppelTable /> */}
            <CoachAppelKpi data={appels} loading={isLoadingAppel} />
          </Paper>
        </Grid.Col>
        <Grid.Col>
          <Paper px={10} pb={30} pt={15} withBorder>
            <ConnexionKpi coachId={params?.id} />
          </Paper>
        </Grid.Col>

        {/* <Grid.Col>
          <Grid>
            <Grid.Col lg={6} md={6}>
             <Paper withBorder p={20} mb={20} radius={10}>
            <Text py={5}>Liste des appels </Text>
              <CoachAppelKpi data={appels} loading={isLoadingAppel}/>

             </Paper>
            </Grid.Col>
            <Grid.Col lg={6} md={6}>
              <Paper px={10} pb={30} pt={15} withBorder>
                <ConnexionKpi coachId={params?.id} />
              </Paper>
            </Grid.Col>
          </Grid>
        </Grid.Col> */}

        {/* fiches infos  */}

        <Grid.Col>
          <Paper withBorder p={20} mb={20} radius={10}>
            <Text>Liste des eleves </Text>
            <StudentsTable />
          </Paper>
        </Grid.Col>
        <Grid.Col>
          <Paper withBorder p={20} mb={20} radius={10}>
            <Text>Liste des Fiches </Text>
            <FicheTable />
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  );
}

export default CaochDetailsPage;
