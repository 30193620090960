const { createSlice } = require("@reduxjs/toolkit");

const useSlice = createSlice({
  name: "userInfo",
  initialState: {
    userInfo: {
      status: null,
      userId: null,
      schoolId: null,
      classeId: null,
      csrfToken: null,
    },
    currentDiagnostiqueType: null,
    appelOffline: { data: [] },
    elevePositionnement: null,
    niveauPositionnement: null,
  },
  reducers: {
    addUserId: (state, { payload }) => {
      state.userInfo.userId = payload;
    },
    addUserStatus: (state, { payload }) => {
      state.userInfo.status = payload;
    },
    addSchoolId: (state, { payload }) => {
      state.userInfo.schoolId = payload;
    },
    addClasseId: (state, { payload }) => {
      state.userInfo.classeId = payload;
    },
    addCsrfToken: (state, { payload }) => {
      state.userInfo.csrfToken = payload;
    },
    addCurrentDiagnostique: (state, { payload }) => {
      state.currentDiagnostiqueType = payload;
    },
    clearUserInfo: (state) => {
      state.userInfo.userId = null;
      state.userInfo.status = null;
      state.userInfo.schoolId = null;
      state.userInfo.classeId = null;
      state.userInfo.csrfToken = null;
      state.currentDiagnostiqueType = null;
    },

    // For appel offline
    addAppelOffline: (state, { payload }) => {
      if (
        state.appelOffline.data?.find(
          (element) => element.studentId === payload.studentId
        )
      ) {
        state.appelOffline.data = state.appelOffline.data.map((element) => {
          if (element.studentId === payload.studentId) {
            return { ...element, status: payload.status };
          } else {
            return { ...element };
          }
        });
      } else {
        state.appelOffline.data.push(payload);
      }
    },

    //For nineau testDePositionnement
    setNiveauPositionnement: (state, { payload }) => {
      state.niveauPositionnement = payload;
    },

    //For student testDePositionnement
    setElevePositionnement: (state, { payload }) => {
      state.elevePositionnement = payload;
    },

    //Clear info appel offline
    clearAppelOffline: (state) => {
      state.appelOffline.data = [];
    },
  },
});

export const getUserId = (state) => state?.user?.userInfo?.userId;
export const getUserStatus = (state) => state?.user?.userInfo?.status;
export const getSchollId = (state) => state?.user?.userInfo?.schoolId;
export const getClasseId = (state) => state?.user?.userInfo?.classeId;
export const getCsrfToken = (state) => state?.user?.userInfo?.csrfToken;
export const getCurrentDiagnostique = (state) =>
  state?.user?.currentDiagnostiqueType;
export const getElevePositionnement = (state) =>
  state?.user?.elevePositionnement;
export const getAppelsOfflineData = (state) => state?.user?.appelOffline.data;

export const {
  addUserId,
  addUserStatus,
  addSchoolId,
  addClasseId,
  addCsrfToken,
  addCurrentDiagnostique,
  clearUserInfo,
  addAppelOffline,
  setNiveauPositionnement,
  setElevePositionnement,
  clearAppelOffline,
} = useSlice.actions;
export default useSlice.reducer;
