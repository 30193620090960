import { Flex, Paper } from "@mantine/core";
import { NavLink } from "react-router-dom";

const TabViews = ({ tabs }) => {
  return (
    <Paper
      withBorder
      shadow="xs"
      my={20}
      style={{
        backgroundColor: "#fff",
        paddingTop: 7,
        position: "sticky",
        top: 10,
        zIndex: 99,
        overflowX: "auto",
      }}
      radius={10}
    >
      <Flex
        //
        px={10}
        gap={25}
        wrap={"nowrap"}
      >
        {tabs.map((item, index) => (
          <NavLink
            className=""
            style={({ isActive }) => ({
              borderBottom: isActive && "3px solid rgb(78,115,223)",
              height: 30,
              cursor: "pointer",
              textDecoration: "none",
              color: "black",
              fontSize: 15,
              whiteSpace: "nowrap",
            })}
            end
            key={item?.path + index}
            to={item?.path}
          >
            {/* <Text size={15} mx={17} style={{ color: "gray" }}> */}
            {item?.label}
            {/* </Text> */}
            {/* {tabs.length === index + 1 ? null : (
              <div
                style={{
                  height: 20,
                  width: 2,
                  backgroundColor: "rgba(0,0,0,0.1)",
                }}
              ></div>
            )} */}
          </NavLink>
        ))}
      </Flex>
    </Paper>
  );
};

export default TabViews;
