import { useNavigate } from "react-router-dom";
import InputText from "../../../../components/InputText";
import Button from "../../../../components/Button";
import Flex from "../../../../components/Flex";
// import transformInputSelectArray from "../../../../utils/inputSelectData";
import { useState } from "react";
import Box from "../../../../components/Box";
import Text from "../../../../components/Text";
import { Eye, Pencil, Trash } from "tabler-icons-react";

const DiagnostiqueQuizzList = () => {
  const [diagnostiques, setDiagnostiques] = useState(
    JSON.parse(localStorage.getItem("diagnostiques")) || []
  );
  const navigation = useNavigate();
  const [query, setQuery] = useState("");

  function handleDelete(id) {
    const newDiagnostiques = diagnostiques.filter(
      (diagnostique) => diagnostique.id !== id
    );
    localStorage.setItem("diagnostiques", JSON.stringify(newDiagnostiques));
    setDiagnostiques(newDiagnostiques);
  }
  return (
    <div>
      <Flex position={"apart"}>
        <InputText
          placeholder={"Rechercher un ecole"}
          onChange={(value) => setQuery(value.toLowerCase())}
          value={query}
        />
        <Button
          onClick={() => {
            navigation("add");
          }}
        >
          Ajouter
        </Button>
      </Flex>
      <Box wihtBorder style={{ overflowY: "auto" }} my={10}>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Question</th>
              <th>Classe</th>
              <th>Matiere</th>
              <th>Niveau</th>
              <th style={{ textAlign: "end" }}>actions</th>
            </tr>
          </thead>

          <tbody>
            {diagnostiques
              ?.filter((item) => item?.question.toLowerCase().includes(query))
              .map((item) => (
                <tr
                  key={item?.id}
                  style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
                >
                  <td>
                    <Text>{item?.question}</Text>
                  </td>
                  <td>
                    <Text>{item?.classeId}</Text>
                  </td>
                  <td>
                    <Text>{item?.matiereId}</Text>
                  </td>
                  <td>
                    <Text>{item?.niveauId}</Text>
                  </td>
                  <td>
                    <Flex gap={15} position={"end"}>
                      <Eye
                        size={16}
                        className="cursor-pointer"
                        onClick={() => navigation(`${item?.id}`)}
                      />
                      <Pencil
                        className="cursor-pointer"
                        size={16}
                        onClick={() => {
                          // setFor(null);
                          // setOpened(true);
                          // setItemEdited(item);
                          // setForm({
                          // ...form,
                          // name: item?.name,
                          // });
                        }}
                      />
                      <Trash
                        className="cursor-pointer"
                        size={16}
                        onClick={() => handleDelete(item?.id)}
                        color="red"
                      />
                    </Flex>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Box>
    </div>
  );
};

export default DiagnostiqueQuizzList;
