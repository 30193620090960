import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

const eleveApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getEleves: build.query({
      // query: () => API.ELEVES_API.GETALL(),
      query: ({ limit }) =>
        limit ? `eleves/?limit=${limit}&offset=0` : "eleves",
      providesTags: ["Eleves"],
    }),
    getAllEleves: build.query({
      // query: () => API.ELEVES_API.GETALL(),
      query:()=> 'eleves/?type=CONFIRMED',
      providesTags: ["Eleves"],
    }),
    getAllElevesByParent: build.query({
      // query: () => API.ELEVES_API.GETALL(),
      query:(parentId)=> `eleves/?parrentId=${parentId}`,
      providesTags: ["Eleves"],
    }),
    getAllElevesByEcoleByCoach: build.query({
      query: ({ coachId, schoolId, classeId }) =>
        `eleves/coach/${coachId}/schools/${schoolId}/classes/${classeId}/`,
      providesTags: ["Eleves"],
    }),
    getEleve: build.query({
      query: (id) => API.ELEVES_API.GET(id),
      providesTags: ["Eleves"],
    }),
    getEleveByCoach: build.query({
      query:({coachId}) => `/m1/students/coachs/${coachId}/`,
      providesTags: ['Eleves']
    }),
    addEleves: build.mutation({
      query({ body, token }) {
        return {
          url: API.ELEVES_API.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Eleves"],
    }),
    EditEleves: build.mutation({
      query({ body, token }) {
        return {
          url: API.ELEVES_API.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Eleves"],
    }),
    EditEleveClasse: build.mutation({
      query({ body, token }) {
        return {
          url: API.ELEVES_API.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Eleves"],
    }),
    deleteEleves: build.mutation({
      query(id) {
        return {
          url: API.ELEVES_API.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Eleves"],
    }),
  }),
});

export const {
  useGetElevesQuery,
  useGetEleveQuery,
  useGetAllElevesQuery,
  useGetAllElevesByEcoleByCoachQuery,
  useAddElevesMutation,
  useEditElevesMutation,
  useDeleteElevesMutation,
  useGetEleveByCoachQuery,
  useEditEleveClasseMutation,
  useGetAllElevesByParentQuery
} = eleveApi;
