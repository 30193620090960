import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

const classeApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getClasses: build.query({
      query: () => API.CLASSES_API.GETALL(),
      providesTags: ["Classes"],
    }),
    getStudentHistoriqueClasses: build.query({
      query: ({ studentId }) => ({
        url: "/classes/historiques/",
        params: { studentId },
      }),
      providesTags: ["Classes"],
    }),
    addClasses: build.mutation({
      query({ body, token }) {
        return {
          url: API.CLASSES_API.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Classes"],
    }),
    addClasseHistorique: build.mutation({
      query({ body, token }) {
        return {
          url: API.CLASSES_API.ADD_HISTORIQUE(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Classes"],
    }),
    EditClasses: build.mutation({
      query({ body, token }) {
        return {
          url: API.CLASSES_API.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Classes"],
    }),
    deleteClasses: build.mutation({
      query(id) {
        return {
          url: API.CLASSES_API.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Classes"],
    }),
  }),
});

export const {
  useGetClassesQuery,
  useGetStudentHistoriqueClassesQuery,
  useAddClassesMutation,
  useEditClassesMutation,
  useDeleteClassesMutation,
  useAddClasseHistoriqueMutation
} = classeApi;
