import { ActionIcon, Group, Title } from "@mantine/core";
import React from "react";
import { ArrowLeft } from "tabler-icons-react";

function HeaderPage({ navigation, title }) {
  return (
    <Group position="apart">
      <Group noWrap spacing={20}>
        <ActionIcon onClick={() => navigation(-1)}>
          <ArrowLeft />
        </ActionIcon>
        <Title order={4}>{title}</Title>
      </Group>
    </Group>
  );
}

export default HeaderPage;
