import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

const diagnostiqueApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getDiagnostiques: build.query({
      query: () => "diagnostics/",
      providesTags: ["Diagnostics"],
    }),
    getFristEpreuveCoachDiagnostiquess: build.query({
      query: ({ limit }) =>
        limit ? `diagnostics/?limit=${limit}&offset=0` : "diagnostics/",
      providesTags: ["Diagnosticss"],
    }),
    getDiagnostiqueNiveauDetails: build.query({
      query: ({ diagnosticId, niveauId }) =>
        API.DIAGNOSTIUES_API.GET_DETAILS_NIVEAU(diagnosticId, niveauId),
      providesTags: ["Diagnostics", "Epreuves"],
    }),
    getDiagnostiqueByMatierByClasseByNiveau: build.query({
      query: ({ classeId, niveauId, matiereId }) =>
        `/diagnostics/?matiereId=${matiereId}&classeId=${classeId}&niveauId=${niveauId}`,
      providesTags: ["Diagnostics"],
    }),
    getDiagnostiqueByClasse: build.query({
      query: (classeId) => `/diagnostics/?classeId=${classeId}`,
      providesTags: ["Diagnostics"],
    }),
    getDiagnostiqueTentavivesByEleves: build.query({
      query: (eleveId) => `/m1/diagnostics/students/${eleveId}/`,
      providesTags: ["Diagnostics"],
    }),
    getDiagnostiqueTentavivesByElevesGroupeByTententative: build.query({
      query: ({ eleveId, diagnosticId }) =>
        `/m1/diagnostics/notes/?studentId=${eleveId}&diagnostic=${diagnosticId}`,
      providesTags: ["Diagnostics"],
    }),
    getDiagnostiqueTentavivesByElevesGroupeByTententativeDetails: build.query({
      query: (tentativeId) =>
        `/m1/diagnostics/tentative/?tentativeId=${tentativeId}`,
      providesTags: ["Diagnostics"],
    }),
    // m1/diagnostics/students/<int:id>/</int:id>
    getDiagnostiqueTentativeByCoach: build.query({
      query: (id) => `/diagnostics/eleves/?coachId=${id}`,
      providesTags: ["Diagnostics"],
    }),
    getDiagnostiqueTentativeByEleve: build.query({
      query: (id) => `/diagnostics/eleves/?studentId=${id}`,
      providesTags: ["Diagnostics"],
    }),
    getDiagnostiqueNoteByEleve: build.query({
      query: ({ eleveId, diagnosticId }) =>
        `notes/?eleveId=${eleveId}&epreuveDiagnostic=${diagnosticId}`,
      providesTags: ["Diagnostics"],
    }),
    getEpreuvexByDiagnostique: build.query({
      query: (diagnosticId) =>
        `/diagnostics/parents/?diagnosticId=${diagnosticId}&type_diagnostic=PARENT`,
      providesTags: ["Diagnostics"],
    }),
    getEpreuvexByDiagnostiqueWithNiveau: build.query({
      query: (diagnosticId) => `/epreuves/diagnostic/${diagnosticId}/`,
      providesTags: ["Diagnostics"],
    }),
    getElevesDiagnostiquer: build.query({
      query: ({ diagnosticId, schoolId }) =>
        `/diagnostics/notes/?diagnosticId=${diagnosticId}&schoolId=${schoolId}`,
      providesTags: ["Diagnostics"],
    }),
    getElevesNotesDiagnostiquer: build.query({
      query: (eleveId) => `/notes/?eleveId=${eleveId}`,
      providesTags: ["Diagnostics"],
    }),
    addDiagnostiques: build.mutation({
      query({ body, token }) {
        return {
          url: API.DIAGNOSTIUES_API.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Diagnostics"],
    }),
    EditDiagnostiques: build.mutation({
      query({ body, token }) {
        return {
          url: API.DIAGNOSTIUES_API.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Diagnostics"],
    }),
    deleteDiagnostiques: build.mutation({
      query(id) {
        return {
          url: API.DIAGNOSTIUES_API.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Diagnostics"],
    }),
  }),
});

export const {
  useGetDiagnostiquesQuery,
  useGetFristEpreuveCoachDiagnostiquessQuery,
  useAddDiagnostiquesMutation,
  useEditDiagnostiquesMutation,
  useDeleteDiagnostiquesMutation,
  useGetDiagnostiqueNiveauDetailsQuery,
  useGetDiagnostiqueByClasseQuery,
  useGetDiagnostiqueByMatierByClasseByNiveauQuery,
  useGetEpreuvexByDiagnostiqueQuery,
  useGetElevesDiagnostiquerQuery,
  useGetElevesNotesDiagnostiquerQuery,
  useGetDiagnostiqueTentativeByCoachQuery,
  useGetDiagnostiqueTentativeByEleveQuery,
  useGetDiagnostiqueTentavivesByElevesQuery,
  useGetDiagnostiqueTentavivesByElevesGroupeByTententativeQuery,
  useGetDiagnostiqueTentavivesByElevesGroupeByTententativeDetailsQuery,
  useGetDiagnostiqueNoteByEleveQuery,
  useGetEpreuvexByDiagnostiqueWithNiveauQuery,
} = diagnostiqueApi;
