import React from "react";
import styles from "../assets/styles.module.css";
import Flex from "./Flex";

function Button({
  children,
  type,
  bg,
  color,
  h,
  onClick,
  style,
  isLoading,
  icon,
  mt,
  mb,
  ml,
  mr,
  mx,
  my,
  w,
  flex,
}) {
  return (
    <button
      style={{
        backgroundColor: bg,
        color: color,
        height: h,
        flex: flex,
        width: w,
        ...style,
        marginTop: mt ?? my,
        marginBottom: mb ?? my,
        marginLeft: ml ?? mx,
        marginRight: mr ?? mx,
      }}
      className={styles.Button}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick(e);
        }
      }}
      disabled={isLoading}
      type={type}
    >
      <Flex gap={5}>
        {isLoading && (
          <span
            className={styles.ButtonLoader}
            style={{ color: color, width: 15, height: 15 }}
          ></span>
        )}
        {!isLoading && icon && icon}
        {children}
      </Flex>
    </button>
  );
}

export default Button;
