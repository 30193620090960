import { Box, Button, Group, Select, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import { useGetClassesQuery } from "../../redux/features/classeApi";
import { useGetNiveauxQuery } from "../../redux/features/niveauApi";
import { useAddFichesMutation } from "../../redux/features/ficheApi";
import { useNavigate } from "react-router-dom";
// import { useGetMatiereQuery } from "../../redux/features/matiereApi";

function FicheAjoutPage() {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
    // toolbar: false,
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const navigate = useNavigate();

  const { data: classes } = useGetClassesQuery();
  const { data: niveaux } = useGetNiveauxQuery();
  //   const { data: matieres } = useGetMatiereQuery();
  const [ADD_FICHE, { isLoading }] = useAddFichesMutation();

  const [text, setText] = useState("");

  const form = useForm({
    initialValues: {
      name: "",
      //   materiel: "",
      classeId: "",
      niveaux: "",
      objectifPedagogique: "",
    },

    validate: {
      name: (value) => (value ? null : "remplir le champ"),
    },
  });

  const handleSubmit = async (value) => {
    try {
      const res = await ADD_FICHE({
        body: { ...value, contenu: text },
        token: "",
      });

      if (!res?.data) {
        return alert("Une error c'est produite l'ors de l'ajout");
      }

      navigate(-1);
      //   console.log({ res });
    } catch (error) {
      throw error;
    }
  };

  return (
    <div>
      <Box>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Group mb={10}>
            <TextInput
              placeholder="nom de la fiche"
              {...form.getInputProps("name")}
            />
            <Select
              placeholder="selectionner une classe"
              data={
                classes
                  ? classes?.data.map((item) => ({
                      value: item?.id,
                      label: item?.name,
                    }))
                  : []
              }
              {...form.getInputProps("classeId")}
              searchable
              clearable
            />

            <Select
              placeholder="selectionner un niveauId"
              data={
                niveaux
                  ? niveaux?.data.map((item) => ({
                      value: item?.id,
                      label: item?.name,
                    }))
                  : []
              }
              {...form.getInputProps("niveaux")}
              searchable
              clearable
            />

            {/* <Select
              placeholder="selectionner une matiere"
              data={
                matieres
                  ? matieres?.data.map((item) => ({
                      value: item?.id,
                      label: item?.name,
                    }))
                  : []
              }
              //   {...form.getInputProps("niveauId")}
              searchable
              clearable
            /> */}
          </Group>

          <Textarea
            placeholder="Oblectif pedagogique"
            mb={10}
            {...form.getInputProps("objectifPedagogique")}
          />

          <ReactQuill
            theme="snow"
            value={text}
            onChange={(e) => setText(e)}
            modules={modules}
            formats={formats}
            placeholder="Mettre le contenue de la fiche icci"
          />

          <Group mt={10}>
            <Button type="submit" loading={isLoading}>
              ajouter la fiche
            </Button>
            <Button color="gray">Annuler</Button>
          </Group>
        </form>
      </Box>
    </div>
  );
}

export default FicheAjoutPage;
