import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetDiagnosticStudentsListeByDiagnosticByStudentQuery } from "../../../redux/features/noteApi";
import { ActionIcon, Box, Divider, Group, Loader, Title } from "@mantine/core";
import BulletinDiagnostiqueEleveClasseTable from "../components/BulletinDiagnostiqueEleveClasseTable";
import { ArrowLeft } from "tabler-icons-react";

function BulletinEleve() {
  const params = useParams();
  const navigation = useNavigate();
  const { data, isLoading } =
    useGetDiagnosticStudentsListeByDiagnosticByStudentQuery({
      diagnosticId: parseInt(params?.id),
      studentId: parseInt(params?.studentId),
    });

  console.log(data);

  return (
    <Box>
      <Group>
        <ActionIcon onClick={() => navigation(-1)}>
          <ArrowLeft />
        </ActionIcon>
        <Title order={3}>Liste des diagnostics de l'eleves</Title>
      </Group>

      <Divider my={20} />

      {isLoading ? (
        <Loader />
      ) : (
        <BulletinDiagnostiqueEleveClasseTable data={data?.diagnostics ?? []} />
      )}
    </Box>
  );
}

export default BulletinEleve;
