import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

const compositionApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getCompositions: build.query({
      query: () => API.COMPOSITION.GETALL(),
      providesTags: ["Compositions"],
    }),
    getCompositionsByClasse: build.query({
      query: (id) => API.COMPOSITION.GET_ALL_BY_CLASSE(id),
      providesTags: ["Compositions"],
    }),
    getCompositionsByEleve: build.query({
      query: (id) => API.COMPOSITION.GET_ALL_BY_ELEVE(id),
      providesTags: ["Compositions"],
    }),
    addCompositions: build.mutation({
      query({ body, token }) {
        return {
          url: API.COMPOSITION.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Compositions"],
    }),
    EditCompositions: build.mutation({
      query({ body, token }) {
        return {
          url: API.COMPOSITION.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Compositions"],
    }),
    deleteCompositions: build.mutation({
      query(id) {
        return {
          url: API.COMPOSITION.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Compositions"],
    }),
  }),
});

export const {
  useGetCompositionsQuery,
  useGetCompositionsByClasseQuery,
  useGetCompositionsByEleveQuery,
  useAddCompositionsMutation,
  useEditCompositionsMutation,
  useDeleteCompositionsMutation,
} = compositionApi;
