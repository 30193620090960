import { Box, Text, Badge } from "@mantine/core";
import EperuveTypeHeader from "./compenents/EpreuveTypeHeader";

const EpreuveFigure = ({epreuve}) => {
    return ( 
        <Box>
            <h1>organisation</h1>
            <Box>
                {
                    console.log(epreuve)
                }
                <EperuveTypeHeader epreuve={epreuve} />
                <Box my={10}>
                    <Text>Les formes disponibles :</Text>
                    {epreuve?.consigne?.map((forme, index) => (
                        <Badge
                            key={index} // Utilisez l'index comme clé unique
                            color="blue"
                            className="text-dark me-3"
                            variant="outline"
                        >
                            {forme}
                        </Badge>
                    ))}
                </Box>
                <Box mt={15}>
                    <Text>La correction</Text>
                    {
                        epreuve?.correction_dp?.join(" ").split(" ")
                        .map((choice) =>
                            <Badge 
                                color="gray" 
                                className="text-dark me-3"
                                variant="outline" 
                            >
                                {choice} 
                            </Badge>)
                    }
                </Box>
            </Box>
        </Box>
     );
}
 
export default EpreuveFigure;