import { useMemo, useState } from "react";
import { Table, ScrollArea, Group, Text, ActionIcon } from "@mantine/core";
import { Edit, Trash } from "tabler-icons-react";
import { sortData } from "../../../utils/tableActions";
import Th from "../../../components/Th";

export default function NiveauxTable({ data, handleDelete, handleEdit }) {
  // const [search, setSearch] = useState("");
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search: "" }));
  };

  useMemo(() => {
    setSortedData(data);
  }, [data]);

  const rows = sortedData.map((row) => (
    <tr key={row?.id}>
      <td>
        <Group noWrap>
          <Text weight={600}>{row?.name}</Text>
        </Group>
      </td>
      <td>
        <Group noWrap>
          <Text weight={600}>{row?.matiere?.name}</Text>
        </Group>
      </td>
      <td>
        <Group position="right">
          <ActionIcon color="green" onClick={() => handleEdit(row.id, row)}>
            <Edit size={15} />
          </ActionIcon>
          <ActionIcon onClick={() => handleDelete(row.id)}>
            <Trash size={15} color="red" />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  return (
    <ScrollArea>
      <Table
        horizontalSpacing="md"
        verticalSpacing="xs"
        miw={700}
        sx={{ tableLayout: "fixed" }}
        bg={"#fff"}
      >
        <thead>
          <tr>
            <Th
              sorted={sortBy === "name"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("name")}
            >
              Niveaux
            </Th>
            <Th
              sorted={sortBy === "matiere"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("matiere")}
            >
              Matieres
            </Th>
            <th style={{ textAlign: "right" }}>action</th>
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <tr>
              {/* <td colSpan={Object.keys(data[0]).length}> */}
              <Text weight={500} align="center">
                Nothing found
              </Text>
              {/* </td> */}
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
}
