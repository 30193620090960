import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Loader,
  Modal,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import EditAction from "../actions/EditAction";
import AddAction from "../actions/AddAction";
import {
  useAddMaitresMutation,
  useDeleteMaitresMutation,
  useEditMaitresMutation,
  useGetMaitresQuery,
} from "../../../redux/features/maitreApi";
import MaitreTable from "../components/MaitreTable";
import DeleteAction from "../actions/DeleteAction";
import { useGetClassesQuery } from "../../../redux/features/classeApi";
import { useGetEcolesQuery } from "../../../redux/features/ecoleApi";

function EcoleMaitrePage() {
  const { data, isLoading } = useGetMaitresQuery();
  const { data: classes } = useGetClassesQuery();
  const { data: schools } = useGetEcolesQuery();

  const [add, { isLoading: addLoading }] = useAddMaitresMutation();
  const [edit, { isLoading: editLoading }] = useEditMaitresMutation();
  const [remove] = useDeleteMaitresMutation();

  //   console.log(data);

  const [opened, { open, close }] = useDisclosure(false);
  const [itemEdited, setItemEdited] = useState(null);

  const [query, setQuery] = useState("");

  const form = useForm({
    initialValues: {
      fullName: "",
      phone: "",
      //   schoolId: "",
    },

    validate: {
      fullName: (value) => (value === "" ? "Ce champ est obligatoire" : null),
      phone: (value) => (value === "" ? "Ce champ est obligatoire" : null),
      //   schoolId: (value) => (value === "" ? "Ce champ est obligatoire" : null),
    },
  });

  return (
    <Box>
      <Group position="apart">
        <TextInput
          placeholder="Recherche"
          onChange={(e) => setQuery(e.target.value?.toLowerCase())}
        />
        <Button onClick={open}>Ajouter un maitre</Button>
      </Group>

      <Box my={30}>
        {/* Table */}
        {isLoading ? (
          <Loader />
        ) : data?.data.length === 0 ? (
          <Text>Pas de donnee</Text>
        ) : (
          <MaitreTable
            data={
              data?.data
                ? data?.data?.filterData(["fullName", "phone"], query)
                : []
            }
            handleEdit={(id, item) => {
              setItemEdited(id);
              form.setValues({
                ...item,
                classeId: item?.classeId?.id,
                schoolId: item?.schoolId?.id,
              });
              open();
            }}
            handleDelete={async (id) => await DeleteAction(remove, id)}
          />
        )}
      </Box>

      {/* My modal */}
      <Modal
        opened={opened}
        onClose={() => {
          close();
          form.reset();
          setItemEdited(null);
        }}
        title={itemEdited ? "Modifier le maitre" : "Ajouter un maitre"}
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            if (itemEdited) {
              await EditAction(edit, values);
            } else {
              await AddAction(add, values);
            }
            close();
            form.reset();
          })}
        >
          <TextInput
            label={"Prenom et nom"}
            placeholder={"paull ba"}
            {...form.getInputProps("fullName")}
            mb={10}
          />
          <TextInput
            label={"Telephone"}
            placeholder={"78 3356 65 75"}
            {...form.getInputProps("phone")}
          />

          <Select
            mt={15}
            label={"Selectionner une ecole"}
            data={
              schools?.data.map((item) => ({
                value: item?.id,
                label: item?.name,
              })) ?? []
            }
            placeholder={"Selectionner"}
            {...form.getInputProps("schoolId")}
          />

          <Select
            mt={15}
            label={"Selectionner une classe"}
            data={
              classes?.data.map((item) => ({
                value: item?.id,
                label: item?.name,
              })) ?? []
            }
            placeholder={"Selectionner"}
            {...form.getInputProps("classeId")}
          />

          <Divider my={15} />
          <Flex position={"end"} gap={15}>
            <Button color={"gray"} onClick={close}>
              Annuler
            </Button>
            <Button type={"submit"} loading={addLoading || editLoading}>
              {itemEdited ? "Modifier" : "Ajouter"}
            </Button>
          </Flex>
        </form>
      </Modal>
    </Box>
  );
}

export default EcoleMaitrePage;
