import { useMemo, useState } from "react";

function useGetDebounceData({ firstQuery, allQuery, limit }) {
  const { data: firstData, isLoading: firstDataLoading } = firstQuery({
    limit,
  });
  const { data: allData, isLoading: allDataLoading } = allQuery();

  const [data, setData] = useState([]);

  useMemo(() => {
    if (allData?.data) {
      setData([...allData?.data]);
    } else if (firstData?.data.length > 0) {
      setData([...firstData?.data]);
    }
  }, [allData, firstData]);

  return [data, firstDataLoading, allDataLoading];
}

export default useGetDebounceData;
