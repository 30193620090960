import { ActionIcon, Checkbox, Group, Text } from "@mantine/core";
import { Edit, Eye, Trash } from "tabler-icons-react";

const renderFieldValue = (row, field) => {
  const fieldNames = field.split(".");
  let value = row;

  for (const fieldName of fieldNames) {
    value = value[fieldName] ?? "";
  }

  if (fieldNames.length > 0) {
    return <Text weight={600}>{value}</Text>;
  }

  return value;
};

const TableWithPaginationRow = ({
  row,
  updatedFields,
  navigation,
  handleEdit,
  handleDelete,
  selection,
  toggleRow,
  check = false,
  id,
  otherAction,
}) => (
  <tr key={row[updatedFields[0].field]}>
    {check ? (
      <td>
        <Checkbox
          checked={selection.includes(id)}
          onChange={() => toggleRow(id)}
        />
      </td>
    ) : null}
    {updatedFields.map((field, index) => (
      <td key={index + "" + field.field}>
        {renderFieldValue(row, field.field)}
      </td>
    ))}
    <td>
      <Group position="right" noWrap spacing={1}>
        {navigation ? (
          <ActionIcon
            color="blue"
            onClick={() => (!navigation ? null : navigation(`${id}`))}
          >
            <Eye size={15} />
          </ActionIcon>
        ) : null}
        {handleEdit ? (
          <ActionIcon color="green" onClick={() => handleEdit(id, row)}>
            <Edit size={15} />
          </ActionIcon>
        ) : null}
        {handleDelete ? (
          <ActionIcon onClick={() => handleDelete(id, row)}>
            <Trash size={15} color="red" />
          </ActionIcon>
        ) : null}
        {otherAction ? otherAction(row?.id, row) : null}
      </Group>
    </td>
  </tr>
);

export default TableWithPaginationRow;
