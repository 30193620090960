import React from "react";
import { useGetClassesQuery } from "../../redux/features/classeApi";
import { Box, Divider, Grid, Loader, Paper, Text, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";

function CompositionPage() {
  const { data, isLoading } = useGetClassesQuery();
  const navigation = useNavigate();

  return (
    <Box>
      <Title order={3}>La page composition</Title>

      {isLoading ? (
        <Loader />
      ) : (
        <Grid mt={20} >
          {data?.data.map((item) => (
            <Grid.Col
              key={item?.id}
              lg={2}
              md={3}
              sm={6}
              xs={12}
              onClick={() => navigation(`${item?.id}`)}
            >
              <Paper shadow="sm" p={10} m={10} radius={10} style={{cursor:"pointer"}}>
                <Text>{item?.name}</Text>
                <Divider my={10} />
                <Title order={3}>{item?.students_count}</Title>
              </Paper>
            </Grid.Col>
          ))}
        </Grid>
      )}
    </Box>
  );
}

export default CompositionPage;
