import {
  ActionIcon,
  Box,
  Flex,
  Group,
  MultiSelect,
  NumberInput,
  Paper,
  Select,
  Textarea,
  TextInput,
} from "@mantine/core";
import React from "react";
import { X } from "tabler-icons-react";
import { ListIcons } from "../../../../assets/ListIcons";

function FigureForm({ index, form, consigne }) {
  return (
    <Paper mb={20} p={10} shadow="xs">
      <Group noWrap position="apart">
        <Box style={{ flex: 1 }}>
          <TextInput
            {...form.getInputProps(`epreuves.${index}.titre`)}
            placeholder="Mettre le titre"
            mb={10}
          />
          <Flex gap={10}>
            <MultiSelect
              {...form.getInputProps(`epreuves.${index}.consigne`)}
              label="Selectionne les elemnts a afficher"
              placeholder="Pick values"
              data={ListIcons?.map((item) => ({
                value: item?.name + "-" + item?.id,
                label: item?.icon,
              }))}
              style={{ flex: 1 }}
            />

            <MultiSelect
              {...form.getInputProps(`epreuves.${index}.correction_dp`)}
              label="Selectionne le bon element"
              placeholder="selectionner"
              data={
                consigne
                  ? consigne?.map((item) => ({
                      value: item,
                      label: ListIcons?.find(
                        (_item) => `${_item?.name}-${_item?.id}` === item
                      )?.icon,
                    }))
                  : []
              }
            />
          </Flex>
        </Box>
        <ActionIcon
          color="red"
          onClick={() => form.removeListItem(`epreuves`, index)}
        >
          <X size="1rem" />
        </ActionIcon>
      </Group>
    </Paper>
  );
}

export default FigureForm;
