import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

export const epreuveApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getEpreuves: build.query({
      query: () => API.EPREUVES_API.GETALL(),
      providesTags: ["Epreuves"],
    }),
    getFristEpreuvesCoach: build.query({
      query: ({ limit }) =>
        limit ? `epreuves/?limit=${limit}&offset=0` : "epreuves/",
      providesTags: ["Epreuves"],
    }),
    getEpreuve: build.query({
      query: (id) => API.EPREUVES_API.GET(id),
      providesTags: ["Epreuves"],
    }),
    addEpreuves: build.mutation({
      query({ body, token }) {
        return {
          url: API.EPREUVES_API.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Epreuves"],
    }),
    EditEpreuves: build.mutation({
      query({ body, token }) {
        return {
          url: API.EPREUVES_API.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Epreuves"],
    }),
    ArchiveEpreuve: build.mutation({
      query({ body, token }) {
        return {
          url: API.EPREUVES_API.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Epreuves"],
    }),
    deleteEpreuves: build.mutation({
      query(id) {
        return {
          // url: API.EPREUVES_API.DELETE(id),
          url: `epreuves/diagnostic/delete/${id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Epreuves"],
    }),
  }),
});

export const {
  useGetEpreuvesQuery,
  useGetFristEpreuvesCoachQuery,
  useAddEpreuvesMutation,
  useEditEpreuvesMutation,
  useDeleteEpreuvesMutation,
  useArchiveEpreuveMutation,
  useGetEpreuveQuery,
} = epreuveApi;
