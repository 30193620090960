// import React from "react";

// function PartenaireParentPage() {
//   return <div>PartenaireParentPage</div>;
// }

// export default PartenaireParentPage;

import React, { useState } from "react";
import {
  ActionIcon,
  Button,
  Divider,
  Flex,
  Group,
  Input,
  Loader,
  Modal,
  NumberInput,
  ScrollArea,
  Select,
  Table,
} from "@mantine/core";
// import {
//   parentApi,
//   useConvertirProspectQuery,
//   useEditParentsMutation,
//   useGetProspectsQuery,
// } from "../../../redux/features/parentApi";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
// import DetailsParent from "./DetailsParent";
import { useDisclosure } from "@mantine/hooks";
import { Edit } from "tabler-icons-react";
import { useForm } from "@mantine/form";
import EditAction from "../../../eleve/actions/EditAction";
import DetailsParent from "../../../prospects/DetailsParent";
import {
  useGetProspectsQuery,
  parentApi,
  useConvertirProspectQuery,
  useEditParentsMutation,
  useGetProspectsSenAssurancesQuery,
} from "../../../../redux/features/parentApi";
// import EditAction from "../eleve/actions/EditAction";

function PartenaireParentPage({ type }) {
  const [query, setQuery] = useState("");
  const [provenance, setProvenance] = useState("");
  const {
    data: prospects,
    isLoading,
    refetch,
  } = useGetProspectsSenAssurancesQuery();
  const dispatch = useDispatch();
  const [opened, { open, close }] = useDisclosure(false);
  const [editProspect, setEditProspect] = useState({ id: null, credits: null });
  const [editProspectCredit, { isLoading: isCreditLoading }] =
    useEditParentsMutation();

  const hanldeConvert = async (id) => {
    const { status, data, error } = dispatch(
      parentApi.endpoints.convertirProspect.initiate(id)
      // parentApi.endpoints.getPokemon.initiate('bulbasaur')
    )
      .unwrap()
      .then((res) => {
        toast.success("Prospect converti avec success");
        refetch();
      })
      .catch((error) => toast.error(error?.data?.message));
  };

  const form = useForm({
    // initialValues: {
    //   credits: '',
    // },
    validate: {
      credits: (value) =>
        !isNaN(parseFloat(value)) && isFinite(value) && parseFloat(value) >= 0
          ? null
          : "Nombre invalide",
    },
  });

  const handleChangeCredits = async (e) => {
    console.log(e, editProspect.id);
    const body = {
      id: parseInt(editProspect?.id),
      credits: parseInt(e?.credits),
    };
    const res = await EditAction(editProspectCredit, body);
    if (res?.data?.status === "success") {
      setEditProspect({ id: null, credits: null });
      form.reset();
      toast.success("Modification credits reussie");
      close();
      return;
    }
    return toast.error(
      "Un probleme est surveneu veuillez contacter l'equipe technique"
    );
  };

  const rows = prospects?.data
    .filter((data) => {
      const fullName = data?.fullName?.toLowerCase().includes(query);
      const provenanceFilter =
        !provenance ||
        data?.provenance?.toLowerCase() === provenance?.toLowerCase();
      return fullName;
    })
    // .filter((data) => data?.type === type)
    .map((data) => (
      <tr key={data.id}>
        <td>{data?.fullName}</td>
        <td>{data?.phone}</td>
        <td>{data?.countEnfant}</td>
        {/* <td>{data?.provenance}</td> */}
        <td>{data?.agentCommercialFullName}</td>
        <td>{data?.type === "CONFIRMED" ? "CLIENT" : data?.type}</td>
        {type !== "CONFIRMED" && (
          <td className="flex gap-2">
            {data?.credits}
            <ActionIcon
              onClick={() => {
                setEditProspect({ id: data?.id, credits: data?.credits });
                open();
              }}
              size={"xs"}
            >
              <Edit color="orange" />
            </ActionIcon>
          </td>
        )}
        <td>
          {/* <Button disabled={data.isConverted ||isConverting} loading={isConverting} onClick={()=>hanldeConvert(data.id)}> */}
          <Button
            disabled={data?.type === "CONFIRMED"}
            onClick={() => hanldeConvert(data.id)}
            size="sm"
          >
            {data?.type === "CONFIRMED" ? "Deja converti" : "Convertir"}
          </Button>
        </td>
        <td style={{ textAlign: "end" }}>
          {/* <Button onClick={open}>Open Modal</Button> */}
          <DetailsParent parent={{ id: data?.id, fullName: data?.fullName }} />
        </td>
      </tr>
    ));

  const TableBody = () => {
    if (rows?.length === 0 || !rows) {
      return (
        <tr>
          <td colSpan="7" style={{ textAlign: "center" }}>
            Pas de données
          </td>
        </tr>
      );
    }

    return rows;
  };

  return (
    <div>
      <Flex justify={"space-between"}>
        <Input
          placeholder={`Rechercher un ${
            type ? type?.toLowerCase() : "partenaire"
          }`}
          onChange={(e) => setQuery(e?.target?.value?.toLowerCase())}
          value={query}
        />
        {/* <Select
          placeholder="Filtrer par provenance"
          data={["GENIMI", "SENASSURANCE"]}
          onChange={(e) => setProvenance(e)}
        /> */}
      </Flex>
      <Divider my={10} />
      {isLoading ? (
        <Loader />
      ) : (
        <ScrollArea>
          <Table
            horizontalSpacing="md"
            verticalSpacing="xs"
            miw={1124}
            sx={{ tableLayout: "fixed" }}
            bg={"#fff"}
          >
            <thead>
              <tr>
                <th>Parent</th>
                <th>Telephone</th>
                <th>Nombre enfants</th>
                {/* <th>Provenance</th> */}
                <th>Agent commercial</th>
                <th>Status</th>
                {type !== "CONFIRMED" && <th>Credits</th>}
                <th>Convertir en parent</th>
                <th style={{ textAlign: "end" }}>Details</th>
              </tr>
            </thead>
            <tbody>
              {/* {rows}   */}
              <TableBody />
            </tbody>
          </Table>
        </ScrollArea>
      )}
      <Modal
        opened={opened}
        onClose={close}
        title="Modification credits"
        size={"md"}
      >
        {/* Modal content */}
        {/* <form onSubmit={(e)=>handleChangeCredits(e) }> */}
        <form onSubmit={form.onSubmit((values) => handleChangeCredits(values))}>
          <NumberInput
            placeholder="Mettez le nombre de credits"
            {...form.getInputProps("credits")}
            defaultValue={editProspect.credits}
          />
          <Group mt={10}>
            <Button
              color={"gray"}
              onClick={() => {
                form.reset();
                close();
              }}
            >
              Annuler
            </Button>
            <Button type={"submit"}>
              {isCreditLoading ? "Envoi..." : "Modifier"}
            </Button>
          </Group>
        </form>
      </Modal>
    </div>
  );
}

export default PartenaireParentPage;
