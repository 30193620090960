import { Flex, Group, Select } from "@mantine/core";
import { useState } from "react";
import { Bar } from "react-chartjs-2";
export const BarChart = ({ chartData }) => {
  return (
    <div >
      <Bar
        data={chartData}
        options={{
          plugins: {
            // title: {
            //   display: true,
            //   text: "Users Gained between 2016-2020"
            // },
            legend: {
              display: false 
            },

            scales: {
                x: {
                  stacked: true
                },
                y: {
                  stacked: true
                },
                
            },
            includeInvisible:true,

          }
        }}
      />
    </div>
  );
};