import React from "react";

import { useDisclosure } from "@mantine/hooks";
import {
  Modal,
  Button,
  Loader,
  Table,
  Divider,
  ScrollArea,
} from "@mantine/core";
import { useGetAllElevesByParentQuery } from "../../redux/features/eleveApi";
import { Eye } from "tabler-icons-react";

function DetailsParent({ parent }) {
  const [opened, { open, close }] = useDisclosure(false);
  const { data: students, isLoading } = useGetAllElevesByParentQuery(
    parent.id,
    { skip: !parent.id }
  );

  const StudentByParent = () => {
    const rows = students?.data.map((data) => (
      <tr key={data.id}>
        <td>
          {data?.firstName} {data?.lastName}
        </td>
        <td>{data?.classe?.name}</td>
      </tr>
    ));

    return (
      <div>
        <ScrollArea>
          <Table
            horizontalSpacing="md"
            verticalSpacing="xs"
            miw={124}
            sx={{ tableLayout: "fixed" }}
            bg={"#fff"}
          >
            <thead>
              <tr>
                <th>Eleve</th>
                <th>Classe</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </ScrollArea>
      </div>
    );
  };

  return (
    <>
      <Modal opened={opened} onClose={close} withCloseButton={false}>
        <h4>Les enfants de : {parent.fullName}</h4>
        <Divider my={10} />
        {isLoading ? (
          <Loader />
        ) : students?.data?.length === 0 ? (
          <p>Pas d'enfant </p>
        ) : (
          <StudentByParent />
        )}
      </Modal>

      <Eye onClick={open} color="blue" size={18} />
    </>
  );
}
export default DetailsParent;
