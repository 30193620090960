import { MantineProvider } from "@mantine/core";
import "./App.css";
import Roots from "./routes/Routes";
import { Toaster } from "react-hot-toast";
import { ModalsProvider } from "@mantine/modals";
import "./utils/filterData.js";
import "./utils/formatSelectData.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const env = process.env.REACT_APP_ENV ?? "production";
  // console.log(env);

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        colorScheme: "light",
        primaryColor: env === "production" ? "blue" : "green",
        defaultRadius: 10,
      }}
    >
      <ModalsProvider>
        <Roots />
        <Toaster position="top-center" reverseOrder={false} />
      </ModalsProvider>
    </MantineProvider>
  );
}

export default App;
