import React from 'react'

const InputLabel = ({ register, registerFor, type, label, errors, errorMessage, minLength }) => {
    return (
        <div className="mb-3">
            <label htmlFor={registerFor} className="form-label">{label}</label>
            <input {...register} type={type} className="form-control" id={registerFor} />
            {
                errors && <p className='text-error'>{errorMessage}</p>
            }
        </div>
    )
}

export default InputLabel