async function AddAction(mutationName, data) {
  try {
    const res = await mutationName({ body: data });
    return res;
  } catch (error) {
    console.log({ addError: error });
    return error;
  }
}

export default AddAction;
