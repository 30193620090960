import { useMemo, useState } from "react";
import {
  Table,
  ScrollArea,
  Group,
  Text,
  ActionIcon,
  Badge,
  Avatar,
} from "@mantine/core";
import { Calendar, Eye, Trash } from "tabler-icons-react";
import { sortData } from "../../../utils/tableActions";
import Th from "../../../components/Th";
import { useNavigate } from "react-router-dom";

export default function BulletinDiagnostiqueEleveClasseTable({ data }) {
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const navigation = useNavigate();

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search: "" }));
  };

  useMemo(() => {
    setSortedData(data);
  }, [data]);

  const rows = sortedData.map((row) => (
    <tr key={row?.id}>
      <td>
        <Group noWrap>
          <Calendar color="gray" size={20} />
          <Text weight={600}>
            {new Date(row?.date_diagnostic).toLocaleDateString("fr", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            })}
          </Text>
        </Group>
      </td>
      <td>
        <Badge
          color={
            row?.status === "ENCOURS"
              ? "blue"
              : row?.status === "VALIDE"
              ? "green"
              : "red"
          }
        >
          {row?.status}
        </Badge>
      </td>
      <td>
        <Group>
          <Avatar radius={"xl"}></Avatar>
          <Text size={"sm"}>{row?.createdBy.fullName}</Text>
        </Group>
      </td>
      <td>
        <Group position="right">
          <ActionIcon
            color="blue"
            onClick={() => navigation(`eleve`, { state: row?.id })}
          >
            <Eye size={18} />
          </ActionIcon>
          {/* <ActionIcon color="green">
            <Edit size={18} />
          </ActionIcon> */}
          {/* <ActionIcon>
            <Trash size={18} color="red" />
          </ActionIcon> */}
        </Group>
      </td>
    </tr>
  ));

  return (
    <ScrollArea>
      <Table
        horizontalSpacing="md"
        verticalSpacing="xs"
        miw={700}
        sx={{ tableLayout: "fixed" }}
        bg={"#fff"}
        mb={30}
      >
        <thead>
          <tr>
            <Th
              sorted={sortBy === "firstName"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("firstName")}
            >
              Diagnostics date
            </Th>
            <Th
              sorted={sortBy === "ecole"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("ecole.name")}
            >
              Status
            </Th>
            <Th
              sorted={sortBy === "boursier"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("boursier")}
            >
              Fait par
            </Th>
            <th style={{ textAlign: "right" }}>action</th>
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <tr>
              <td>
                <Text weight={500} align="center">
                  Pas de donnees
                </Text>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
}
