import { Badge, Flex, Text } from "@mantine/core";

const EperuveTypeHeader = ({epreuve}) => {
    return ( 
        <Flex
            mih={50}
            gap="md"
            justify="space-between"
            align="center"
            direction="row"
            wrap="wrap"
        >
            <Text size="md">{epreuve?.niveauId?.name} : {epreuve?.titre}</Text>
            <Badge
                size="xl"
                color={epreuve?.archived?"red":"green"}
            >
                {epreuve?.archived?"Deactive":"Actif"}
            </Badge>
        </Flex>
     );
}
 
export default EperuveTypeHeader;