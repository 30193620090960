import React from "react";
import { useGetDiagnosticNoteByStudentQuery } from "../../../redux/features/noteApi";
import { useLocation } from "react-router-dom";
import { Loader, Table } from "@mantine/core";
import { Checks, X } from "tabler-icons-react";

function BulletinNote() {
  // const params = useParams();
  const location = useLocation();

  const { data, isLoading } = useGetDiagnosticNoteByStudentQuery(
    parseInt(location?.state)
  );

  // console.log(data);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <Table
          horizontalSpacing="md"
          verticalSpacing="xs"
          miw={700}
          sx={{ tableLayout: "fixed" }}
          bg={"#fff"}
        >
          <thead>
            <tr>
              <th>Exercices</th>
              <th>Baremes</th>
              <th>Correction</th>
            </tr>
          </thead>
          <tbody>
            {data?.data?.map((item) => (
              <tr key={item?.id}>
                <td>{item?.epreuve?.titre}</td>
                <td>{item?.epreuve?.bareme ?? "pas de bareme"}</td>
                <td>
                  {item?.note ? (
                    item?.note
                  ) : item?.is_correct ? (
                    <Checks color="green" />
                  ) : (
                    <X color="red" />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default BulletinNote;
