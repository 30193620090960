import React from "react";
import { Breadcrumbs, Divider, Text } from "@mantine/core";
import TabViews from "../../components/TabViews";
import { Outlet } from "react-router-dom";

const tabs = [
  { label: "Liste des paiement de l'eleve", path: "" },
  { label: "Liste des paiement du parent", path: "parent" },
  // { label: "autre choses", path: "autres" },
];

const PaiementPage = () => {
  return (
    <div>
      <Text size={20}>La liste des paiements</Text>
      <TabViews tabs={tabs} />
      <Breadcrumbs />
      <Divider my={20} />
      <div className="">
        <Outlet />
      </div>
    </div>
  );
};

export default PaiementPage;
