import React from "react";
import styles from "../assets/styles.module.css";
import Text from "./Text";
import Box from "./Box";

function InputText({
  placeholder,
  onChange,
  value = "",
  type = "text",
  required,
  disabled,
  label,
  style,
  mt,
  mb,
  w,
}) {
  return (
    <Box style={{ marginTop: mt, marginBottom: mb, width: w }}>
      {label && <Text mb={5}>{label}</Text>}
      <Box px={5} className={styles.inputConatiner}>
        <input
          className={styles.input}
          placeholder={placeholder ?? "recherhcer"}
          style={{ ...style, width: "100%" }}
          value={value}
          type={type}
          disabled={disabled}
          required={required}
          onChange={(e) => onChange && onChange(e.target.value)}
        />
      </Box>
    </Box>
  );
}

export default InputText;
