import {
  Box,
  Button,
  Divider,
  Group,
  Loader,
  Modal,
  Select,
  TextInput,
} from "@mantine/core";
import React, { useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import EditAction from "../../actions/EditAction";
import AddAction from "../../actions/AddAction";
import DeleteAction from "../../actions/DeleteAction";
import { useForm } from "@mantine/form";
import { useGetMatiereQuery } from "../../../../redux/features/matiereApi";
import {
  useAddNiveauxMutation,
  useDeleteNiveauxMutation,
  useEditNiveauxMutation,
  useGetNiveauxQuery,
} from "../../../../redux/features/niveauApi";
import NiveauxTable from "../../components/NiveauxTable";

const NiveauxPage = () => {
  const { data, isLoading } = useGetNiveauxQuery();
  const { data: matieres } = useGetMatiereQuery();
  const [addNiveaux, { isLoading: addLoading }] = useAddNiveauxMutation();
  const [editNiveaux, { isLoading: editLoading }] = useEditNiveauxMutation();
  const [deleteNiveaux] = useDeleteNiveauxMutation();

  const [query, setQuery] = useState("");
  const [opened, { open, close }] = useDisclosure(false);
  const [itemEdited, setItemEdited] = useState(null);

  const form = useForm({
    initialValues: {
      name: "",
      matiereId: "",
    },

    validate: {
      name: (value) => (value === "" ? "Ce champ est obligatoire" : null),
    },
  });

  return (
    <Box>
      {/* <Text size={20}>ClassePage</Text> */}
      <Group position={"apart"}>
        <TextInput
          placeholder={"Rechercher une matiere"}
          onChange={(value) => setQuery(value.target.value?.toLowerCase())}
          value={query}
        />
        <Button onClick={open}>Ajouter</Button>
      </Group>
      <Divider my={20} />

      {isLoading ? (
        <Loader />
      ) : (
        <NiveauxTable
          data={data?.data ? data?.data.filterData(["name"], query) : []}
          handleEdit={(id, item) => {
            setItemEdited(id);
            form.setValues({
              ...item,
            });
            open();
          }}
          handleDelete={async (id) => await DeleteAction(deleteNiveaux, id)}
        />
      )}

      {/* My modal */}
      <Modal
        opened={opened}
        onClose={close}
        title={itemEdited ? "Modifier le niveaux" : "Ajouter un niveaux"}
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            if (itemEdited) {
              await EditAction(editNiveaux, values);
            } else {
              await AddAction(addNiveaux, values);
            }
            close();
            form.reset();
          })}
        >
          <TextInput
            label={"Nom du niveau"}
            placeholder={"niveau name"}
            {...form.getInputProps("name")}
          />

          <Select
            mt={15}
            label={"Selectionner une matiere"}
            data={
              matieres?.data.map((matiere) => ({
                value: matiere?.id,
                label: matiere?.name,
              })) ?? []
            }
            placeholder={"Selectionner"}
            {...form.getInputProps("matiereId")}
          />

          <Divider my={15} />
          <Group position={"right"}>
            <Button color={"gray"} onClick={close}>
              Annuler
            </Button>
            <Button type={"submit"} loading={addLoading || editLoading}>
              {itemEdited ? "Modifier" : "Ajouter"}
            </Button>
          </Group>
        </form>
      </Modal>
    </Box>
  );
};

export default NiveauxPage;
