import { Checkbox, Loader, ScrollArea, Table, Text } from "@mantine/core";
import TableWithPaginationRow from "./TableWithPaginationRow";
import { v4 as uuidv4 } from "uuid";

const TableWithPagination = ({
  data,
  isLoading,
  fields,
  navigation,
  handleEdit,
  handleDelete,
  toggleAll,
  selection,
  toggleRow,
  check,
  otherAction,
}) => {
  const updatedFields = fields.filter((field) => field?.field !== "id");

  const rows = data.map((row, index) => (
    <TableWithPaginationRow
      key={row[fields[0].field] + " " + index}
      row={row}
      id={row?.id}
      check={check}
      selection={selection}
      toggleRow={toggleRow}
      updatedFields={updatedFields}
      navigation={navigation}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      otherAction={otherAction}
    />
  ));

  return isLoading ? (
    <Loader />
  ) : (
    <ScrollArea>
      <Table
        withBorder
        horizontalSpacing="md"
        verticalSpacing="xs"
        miw={700}
        sx={{ tableLayout: "fixed" }}
        bg={"#fff"}
        striped
        highlightOnHover
      >
        <thead>
          <tr>
            {check ? (
              <td style={{ width: 50 }}>
                <Checkbox
                  onChange={toggleAll}
                  checked={selection.length === data.length}
                  indeterminate={
                    selection.length > 0 && selection.length !== data.length
                  }
                />
              </td>
            ) : null}
            {updatedFields?.map((field, index) => (
              <td key={index + " " + uuidv4()}>{field.label}</td>
            ))}
            <td style={{ textAlign: "end" }}>actions</td>
          </tr>
        </thead>

        <tbody>
          {data.length > 0 ? (
            rows
          ) : (
            <tr>
              <td>
                <Text align="center">Pas de donnees</Text>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
};

export default TableWithPagination;
