import { Box, Button, Divider, Flex, Input, Loader, ScrollArea, Table, Text } from '@mantine/core';
import React, { useState } from 'react'
import { parentApi, useGetProspectsQuery } from '../../../redux/features/parentApi';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { useGetCoachsMetricsQuery } from '../../../redux/features/coachApi';
import { IconArrowDownRight, IconArrowUpRight, IconEqual } from '@tabler/icons-react';

export default function CoachMetricsTable() {
    const [query, setQuery] = useState("");
    const { data:prospects,isLoading:iSpLOADING, refetch} = useGetProspectsQuery();
    const {data:metrics,isLoading} = useGetCoachsMetricsQuery()
    const dispatch = useDispatch()
    console.log(metrics?.data)


    const rows = metrics?.data
        .filter(data => data?.coach?.full_name?.toLowerCase().includes(query))
        .map((data) => (
            <tr key={data.coach.id}>
                <td>{data.coach.full_name}</td>
                <td>{data.coach.phone}</td>
                {
                    data.stats.map(stat=> {
                    const rate = stat.count > stat.previous
                        ? 'increase'
                        : stat.count < stat.previous
                        ? 'decrease': 'equal'

                        const DiffIcon = rate ==="increase" 
                        ? IconArrowUpRight
                        : rate==="decrease" 
                        ? IconArrowDownRight :IconEqual

                        return (
                            <td align="flex-end" gap="xs" mt={25}>
                            <Text>Total {stat.title} : {stat.count} </Text>
                            <Text c={rate ==="increase" ? 'teal' : rate==="decrease" ? 'red': 'blue'} fz="sm" fw={500} >
                                <span>{stat.growth_rate}% </span>
                                <DiffIcon size="1rem" stroke={1.5} />
                                <span>precedent: {stat.previous}</span>
                            </Text>
                            </td>
                        )
                    })
                }
                {/* <td colSpan={2}>{data.email}</td>
                <td>{data.fullNameEnfant}</td>
                <td>{data.school}</td>
                <td>{data.classe.name}</td> */}
            </tr>
      ));
    return (
        <Box my={10}>
            <Flex>
                <Input
                    placeholder={"Rechercher un coach"}
                    onChange={(e) => setQuery(e.target.value.toLowerCase())}
                    value={query}
                />
            </Flex>
            <Divider my={10}/>
            {
                isLoading?
                <Loader />:
                (
                    <ScrollArea>
                        <Table
                            horizontalSpacing="md"
                            verticalSpacing="xs"
                            miw={1124}
                            sx={{ tableLayout: "fixed" }}
                            bg={"#fff"}
                            my={10}
                        >
                            <thead>
                            <tr>
                                <th>Coach</th>
                                <th>Telephone</th>
                                <th >Appels</th>
                                <th >Connexion</th>
                                {/* <th >Email</th> */}
                                {/* <th>Enfants</th>
                                <th>Ecole</th>
                                <th>Classe</th> */}
                            </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </Table>
                    </ScrollArea>
                )
            }
            
        </Box>
    )
}
