import { ActionIcon, Box, Group, Paper, TextInput } from "@mantine/core";
import React from "react";
import { X } from "tabler-icons-react";

function TextSpeachForm({ index, form }) {
  return (
    <Paper mb={20} p={10} shadow="xs">
      <Group noWrap position="apart">
        <Box style={{ flex: 1 }}>
          <TextInput
            // style={{ flex: 1 }}
            {...form.getInputProps(`epreuves.${index}.titre`)}
            placeholder="Mettre le titre"
            mb={10}
          />
          <TextInput
            // style={{ flex: 1 }}
            {...form.getInputProps(`epreuves.${index}.correction_dp`)}
            placeholder="Mettre le mot a lire ici"
            // mb={10}
          />
        </Box>
        <ActionIcon
          color="red"
          onClick={() => form.removeListItem(`epreuves`, index)}
        >
          <X size="1rem" />
        </ActionIcon>
      </Group>
    </Paper>
  );
}

export default TextSpeachForm;
