import { useState } from "react";
import { useForm } from "react-hook-form";
import Flex from "../../../../components/Flex";
import InputSelect from "../../../../components/InputSelect";
import { useGetMatiereQuery } from "../../../../redux/features/matiereApi";
import transformInputSelectArray from "../../../../utils/inputSelectData";
import { useGetNiveauxQuery } from "../../../../redux/features/niveauApi";
import { useGetClassesQuery } from "../../../../redux/features/classeApi";
import InputLabel from "../../../../components/InputLabel";
import { useNavigate } from "react-router-dom";

const DiagnostiqueQuizz = () => {
    const { register, setError, formState: { errors }, handleSubmit } = useForm();
    const [isLoading,setIsLoading] = useState(false)
    const { data:matieres} = useGetMatiereQuery();
    const { data:classes} = useGetClassesQuery();
    const {data:niveaux} = useGetNiveauxQuery();
    const [matiere, setMatiere] = useState("");
    const [bonneReponse, setBonneReponse] = useState();
    const [classe, setClasse] = useState("");
    const [niveau, setNiveau] = useState("");
    const navigation = useNavigate()

    const [diagnostiques, setDiagnostiques] = useState(JSON.parse(localStorage.getItem("diagnostiques"))||[]);
    
    const reponses = [
        {
            label:"Reponse 1",
            value: "1",
        },
        {
            label:"Reponse 2",
            value: "2",
        },
        {
            label:"Reponse 3",
            value: "3",
        },
        {
            label:"Reponse 4",
            value: "4",
        },
    ]

    const onSubmit = (data) => {
        data.id = Math.random(Math.random()*1000)
        data.type= "quizz";
        data.matiereId= matiere;
        data.niveauId= niveau;
        data.classeId= classe;
        data.bonne = bonneReponse;
        localStorage.setItem("diagnostiques",JSON.stringify([...diagnostiques,data]))
        setClasse(null);
        setNiveau(null);
        setBonneReponse(null);
        setMatiere(null);
        navigation("/a/diagnostiques/parents/quizz/")
    }


    return ( 
        <>
            <h2 className='fs-5 text-center'>Ajout Diagnostique parent {diagnostiques.length}</h2>
            <div className='vh-100'>
                <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '700px', margin: 'auto', flex: '1' }} className="shadow p-3">
                    <Flex  position={"apart"} >
                        <InputSelect 
                            placeholder="Selectionner une classe"
                            data={transformInputSelectArray(classes?.data,"id","name")}
                            onChange={e=>setClasse(e)}
                        />
                        <InputSelect 
                            data={transformInputSelectArray(niveaux?.data,"id","name")}
                            onChange={e=>setNiveau(e)}
                        />    
                        <InputSelect 
                            placeholder="Selectionner une matiere"
                            data={transformInputSelectArray(matieres?.data,"id","name")}
                            onChange={e=>setMatiere(e)}
                        />
                    </Flex>
                    <InputLabel
                        register={{ ...register("question") }}
                        registerFor="question"
                        type="text" 
                        label="Question"
                     />
                    <InputLabel 
                        register={{ ...register("reponse1") }}
                        registerFor="reponse1"
                        type="text" 
                        label="Reponse 1"
                    />
                    <InputLabel 
                        register={{ ...register("reponse2") }}
                        type="text" 
                        label="Reponse 2"
                    />
                    <InputLabel 
                        register={{ ...register("reponse3") }}
                        type="text" 
                        label="Reponse 3"
                    />
                    <InputLabel 
                        register={{ ...register("reponse4") }}
                        type="text" 
                        label="Reponse 4"
                    />
                    <InputSelect 
                        register={{ ...register("bonne") }}
                        placeholder="Selectionner la bonne reponse"
                        data={reponses}
                        onChange={e=>setBonneReponse(e)}
                    />
                    <button type="submit" className="btn btn-primary w-100 my-3">{isLoading?"Envoi...":'Submit'}</button>
                </form>
            </div>
        </>
     );
}
 
export default DiagnostiqueQuizz;