import { ApiMananger } from "../../../../redux/apiMananger";
import API from "../../../../routes/api.routes";

const mutationsApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    addDiagnosticsNiveau: build.mutation({
      query({ body, token }) {
        return {
          url: API.DIAGNOSTICS_NIVEAU_API.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Diagnostics"],
    }),
    // addNiveauByDiagnostics: build.mutation({
    //   query({ body, token }) {
    //     return {
    //       url: API.DIAGNOSTICS_NIVEAU_API.ADD(),
    //       method: "POST",
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //         "X-CSRFToken": token,
    //       },
    //       body,
    //     };
    //   },
    //   invalidatesTags: ["Diagnostics"],
    // }),
    EditDiagnosticsNiveau: build.mutation({
      query({ body, token }) {
        return {
          url: API.DIAGNOSTICS_NIVEAU_API.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Diagnostics"],
    }),
    deleteDiagnosticsNiveau: build.mutation({
      query(id) {
        return {
          url: API.DIAGNOSTICS_NIVEAU_API.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Diagnostics"],
    }),
  }),
});

export const {
  useAddDiagnosticsNiveauMutation,
  useDeleteDiagnosticsNiveauMutation,
  useEditDiagnosticsNiveauMutation,
} = mutationsApi;
