import { ApiMananger } from "../../../../redux/apiMananger";
import API from "../../../../routes/api.routes";

const diagnostiqueApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getDiagnostique: build.query({
      query: (id) => API.DIAGNOSTIUES_API.GET_WITH_NIVEAUX(id),
      providesTags: ["Diagnostics"],
    }),
    getDiagnostiques: build.query({
      query: () => API.DIAGNOSTIUES_API.GETALL(),
      providesTags: ["Diagnostics"],
    }),
    addDiagnostiques: build.mutation({
      query({ body, token }) {
        return {
          url: API.DIAGNOSTIUES_API.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Diagnostics"],
    }),
    EditDiagnostiques: build.mutation({
      query({ body, token }) {
        return {
          url: API.DIAGNOSTIUES_API.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Diagnostics"],
    }),
    deleteDiagnostiques: build.mutation({
      query(id) {
        return {
          url: API.DIAGNOSTIUES_API.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Diagnostics"],
    }),
  }),
});

export const {
  useGetDiagnostiqueQuery,
  useGetDiagnostiquesQuery,
  useAddDiagnostiquesMutation,
  useEditDiagnostiquesMutation,
  useDeleteDiagnostiquesMutation,
} = diagnostiqueApi;
