import { ApiMananger } from "../../../../redux/apiMananger";
import API from "../../../../routes/api.routes";

const noteApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getKpis: build.query({
      query: () => API.KPI.GETALL(),
      providesTags: ["Kpi"],
    }),
  }),
});

export const { useGetKpisQuery } = noteApi;
