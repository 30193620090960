import React from 'react'
import { useGetMessageQuery } from '../../redux/features/parentApi';
import { Grid, Loader, Text } from '@mantine/core';
import MessageItem from './components/MessageItem';

function ContactPage() {

    const { data:messages,isLoading, refetch} = useGetMessageQuery();
  return (
    <>
        <div className='my-3'>Liste des messages des parents</div>
        {
            isLoading ? (
                <Loader />
            ):(
                messages?.data.length === 0 || !messages?.data ?(
                    <Text>Pas de message</Text>
                ):(
                    <Grid>
                        {
                            messages?.data?.map(
                                message => <MessageItem key={message?.id} parent={message?.parent} message={message?.message} />
                                )
                        }
                    </Grid>
                )
            )
        }
    </>
  )
}

export default ContactPage