import React, { useState } from "react";
import { useGetCompositionsByClasseQuery } from "../../../redux/features/compostionApi";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Group, Loader, Select, TextInput } from "@mantine/core";
import CompositionTable from "../components/CompositionTable";
import HeaderPage from "../../../components/HeaderPage";
import { useGetEcolesQuery } from "../../../redux/features/ecoleApi";

function CompositionLIstePage() {
  const params = useParams();
  const { data: ecoles } = useGetEcolesQuery();

  const { data, isLoading } = useGetCompositionsByClasseQuery(
    parseInt(params?.id)
  );
  const [query, setQuery] = useState("");
  const [sortedClasse, setSortedClasse] = useState("");
  const navigation = useNavigate();
  return (
    <Box>
      {isLoading ? (
        <Loader />
      ) : (
        <>
        <HeaderPage title={`La liste des notes de compositions de la classe :  ${data?.classe}` } navigation={navigation} />
          <Group position="apart" my={10}>
            <TextInput
              placeholder={"Rechercher un eleve"}
              onChange={(e) => setQuery(e.target.value.toLowerCase())}
              value={query}
            />
            <Select
              searchable
              // clearable
              data={
                ecoles?.data.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                })) ??
                [] ??
                []
              }
              placeholder="Ecoles"
              onChange={(e) => setSortedClasse(e)}
            />
          </Group>
          <Box mb={30}>
            <CompositionTable data={
              data?.data?.filter(
                (item) =>
                  item?.student?.fullName.toLowerCase().includes(query) 
                ).filter((item)=>
                !sortedClasse || item?.student?.schoolId === sortedClasse
                )
              ?? []
            } />
          </Box>  
        </>
      )}
    </Box>
  );
}

export default CompositionLIstePage;
