import { useMemo, useState } from "react";
import {
  Table,
  ScrollArea,
  Group,
  Text,
  ActionIcon,
  Avatar,
} from "@mantine/core";
import { Edit, Trash } from "tabler-icons-react";
import { sortData } from "../../../utils/tableActions";
import Th from "../../../components/Th";

export default function MaitreTable({ data, handleDelete, handleEdit }) {
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search: "" }));
  };

  useMemo(() => {
    setSortedData(data);
  }, [data]);

  const rows = sortedData.map((row) => (
    <tr key={row?.id}>
      <td>
        <Group noWrap>
          <Text weight={600}>{row?.fullName}</Text>
        </Group>
      </td>
      <td>
        <Text weight={500} size={17}>
          {row?.phone}
        </Text>
      </td>
      <td>
        <Text weight={row?.schoolId?.name ? 600 : 500} size={17}>
          {row?.schoolId?.name ?? "pas d'ecole"}
        </Text>
      </td>
      <td>
        <Text weight={row?.classeId?.name ? 600 : 500} size={17}>
          {row?.classeId?.name ?? "pas de classe"}
        </Text>
      </td>
      <td>
        <Group position="right">
          <ActionIcon color="green" onClick={() => handleEdit(row.id, row)}>
            <Edit size={15} />
          </ActionIcon>
          <ActionIcon onClick={() => handleDelete(row.id)}>
            <Trash size={15} color="red" />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  return (
    <ScrollArea>
      {/* <TextInput
        placeholder="Search by any field"
        mb="md"
        icon={<Search size="1rem" />}
        value={search}
        onChange={handleSearchChange}
      /> */}
      <Table
        horizontalSpacing="md"
        verticalSpacing="xs"
        miw={700}
        sx={{ tableLayout: "fixed" }}
        bg={"#fff"}
      >
        <thead>
          <tr>
            <Th
              sorted={sortBy === "fullName"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("fullName")}
            >
              Maitres
            </Th>
            <Th
              sorted={sortBy === "phone"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("phone")}
            >
              Numero de Telephone
            </Th>
            <Th
              sorted={sortBy === "ecole"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("ecole")}
            >
              Ecoles
            </Th>
            <Th
              sorted={sortBy === "classe"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("classe")}
            >
              Classes
            </Th>
            <th style={{ textAlign: "right" }}>action</th>
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <tr>
              {/* <td colSpan={Object.keys(data[0]).length}> */}
              <Text weight={500} align="center">
                Nothing found
              </Text>
              {/* </td> */}
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
}
