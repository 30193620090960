import {
  Box,
  Divider,
  Grid,
  Group,
  Loader,
  Paper,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import React from "react";
import { useGetNotesGroupeByClasseQuery } from "../../redux/features/noteApi";
import { useNavigate } from "react-router-dom";

function BulletinPage() {
  const theme = useMantineTheme();
  const navigation = useNavigate();

  const { data, isLoading } = useGetNotesGroupeByClasseQuery();

  // console.log(data);

  return (
    <Box>
      {/* <StatsFirst
        data={[
          {
            // title: "Bulletins",
            stats: "Notes des diagnostics",
            description: "La liste des bulletin par rapport aux classes",
          },
        ]}
      />
      <Space h={30} /> */}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Grid>
            {data?.data.map((item, index) => (
              <Grid.Col
                lg={3}
                md={4}
                sm={4}
                xs={5}
                key={index}
                style={{ flexGrow: 1 }}
              >
                <Paper withBorder p={"md"} radius={10}>
                  <Box p={5}>
                    <Title order={3}>{item?.classe?.name}</Title>
                  </Box>

                  <Divider my={10} />

                  {item?.data.map((matiere, index) => (
                    <Group
                      key={index}
                      position="apart"
                      mb={10}
                      bg={theme.colors.gray[1]}
                      p={10}
                      style={{ borderRadius: 10, cursor: "pointer" }}
                      onClick={() => navigation(`${matiere.diagnosticId}`)}
                    >
                      <Text weight={600} size={"sm"}>
                        {matiere?.matiere}
                      </Text>
                      <Group>
                        <Text weight={600}>{matiere?.diagnostics?.done}</Text>
                        <Text>/</Text>
                        <Text weight={600}>
                          {matiere?.diagnostics?.not_done}
                        </Text>
                        {/* <RingProgress
                          size={80}
                          thickness={8}
                          sections={[{ value: 40, color: "yellow" }]}
                          rootColor="red"
                        /> */}
                      </Group>
                    </Group>
                  ))}
                </Paper>
              </Grid.Col>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
}

export default BulletinPage;
