import React, { useState } from "react";
import {
  useAssignFicheMutation,
  useDeleteFichesMutation,
  useEditFichesMutation,
  useGetFichesQuery,
} from "../../redux/features/ficheApi";
import {
  Box,
  Button,
  Divider,
  Text,
  TextInput,
  Loader,
  Group,
  Center,
  Textarea,
  Select,
  Modal,
  Drawer,
  Checkbox,
  ActionIcon,
} from "@mantine/core";
// import FicheCard from "./components/FicheCard";
import { Link, useNavigate } from "react-router-dom";
import { modals } from "@mantine/modals";
import { useDisclosure } from "@mantine/hooks";
import ReactQuill from "react-quill";
import { useGetClassesQuery } from "../../redux/features/classeApi";
import { useGetNiveauxQuery } from "../../redux/features/niveauApi";
import { configWysiwyg } from "../../utils/wysiwyg";
import { useForm } from "@mantine/form";
import { useGetCoachsQuery } from "../../redux/features/coachApi";
import RenderData from "../../components/RenderData";
import { User, UserPlus } from "tabler-icons-react";
import toast from "react-hot-toast";
import useTableWithPagination from "../../layouts/table";

const FichePage = () => {
  const { data: fiches, isLoading } = useGetFichesQuery();
  const [query, setQuery] = useState("");
  const [queryCoach, setQueryCoach] = useState("");

  const navigate = useNavigate();

  const [ASSIGN_COACH, { isLoading: isAssignLoading }] =
    useAssignFicheMutation();
  const [DELETE_FICHE, { isLoading: deleteFicheLoading }] =
    useDeleteFichesMutation();
  const [EDIT_FICHE, { isLoading: editFicheLoading }] = useEditFichesMutation();

  const { data: classes } = useGetClassesQuery();
  const { data: niveaux } = useGetNiveauxQuery();
  const { data: coachs, isLoading: isCoachLoading } = useGetCoachsQuery({});

  const [opened, { open, close }] = useDisclosure();
  const [openedDrawer, { open: onpenDrawer, close: closeDrawer }] =
    useDisclosure(false);

  const [text, setText] = useState("");

  const [selectionFiche, setSelectionFiche] = useState([]);

  const toggleRowFiche = (id) =>
    setSelectionFiche((current) =>
      current.includes(id)
        ? current.filter((item) => item !== id)
        : [...current, id]
    );
  const toggleAllFiche = () =>
    setSelectionFiche((current) =>
      current.length === fiches?.data.length
        ? []
        : fiches?.data.map((item) => item.id)
    );

  const [selectionCoach, setSelectionCoach] = useState([]);

  const toggleRowCoach = (id) =>
    setSelectionCoach((current) =>
      current.includes(id)
        ? current.filter((item) => item !== id)
        : [...current, id]
    );
  // const toggleAllCoach = () =>
  //   setSelectionCoach((current) =>
  //     current.length === coachs?.data.length
  //       ? []
  //       : coachs?.data.map((item) => item.id)
  //   );

  const form = useForm({
    initialValues: {
      name: "",
      classeId: "",
      niveaux: "",
      objectifPedagogique: "",
    },

    validate: {
      name: (value) => (value ? null : "remplir le champ"),
    },
  });

  const handleAssignFiche = async () => {
    // console.log({ coachId, selection });
    let allRequests = [];

    for (let i = 0; i < selectionFiche.length; i++) {
      for (let j = 0; j < selectionCoach.length; j++) {
        allRequests = [
          ...allRequests,
          { coach: selectionCoach[j], fiches: selectionFiche[i] },
        ];
      }
    }

    // return console.log({ allRequests });
    try {
      closeDrawer();
      const AsignationPromises = allRequests.map((fiche) => {
        return ASSIGN_COACH({
          body: { ...fiche },
          token: "",
        });
      });

      await Promise.all(AsignationPromises);

      setSelectionFiche([]);
      setSelectionCoach([]);
      toast.success("Assignation reussi");
    } catch (error) {
      toast.error("Erreur l'or de l'assignation");
      console.log(error);
      return;
    }
  };

  const handleUpdate = async (value) => {
    try {
      await EDIT_FICHE({ body: { ...value, contenu: text }, token: "" });
      // console.log({ res });
      close();
    } catch (error) {
      throw error;
    }
  };

  const openDeleteModal = (id) =>
    modals.openConfirmModal({
      title: "Supprimer",
      children: <Text size="sm">Voulez-vous supprimer la fiche</Text>,
      labels: {
        confirm: deleteFicheLoading ? "En cours..." : "Confirmer",
        cancel: "annuler",
      },
      onCancel: () => {},
      onConfirm: async () => {
        try {
          await DELETE_FICHE(id);
          toast.success("Suppression reussie");
        } catch (error) {
          console.log("Failed to delete", error);
          toast.error("Une erreur est survenu lor de la suppression");
        }
      },
    });

  // useEffect(() => {
  //   console.log({ classes: classes?.data?.formatArray() });
  //   //
  // }, []);

  const { TableWithPagination: FicheTable } = useTableWithPagination({
    useQuery: useGetFichesQuery,
    searchs: ["name"],
    searchValue: query,
    filters: [
      {
        data: classes ? classes?.data?.formatArray() : [],
        label: "classe",
        key: "classeId",
      },
      // {
      //   data: niveaux ? niveaux?.data?.formatArray() : [],
      //   label: "niveau",
      //   key: "niveaux",
      // },
    ],
    componentOptions: {
      fields: [
        { label: "id", field: "id" },
        { label: "fiche", field: "name" },
        { label: "description", field: "objectifPedagogique", take: 30 },
        { label: "Classe", field: "classe.name" },
        // { label: "Niveau", field: "niveau.name" },
      ],
      navigation: navigate,
      handleAdd: open,
      handleDelete: openDeleteModal,
      handleEdit: (id, fiche) => {
        form.setValues({ ...fiche });
        setText(fiche?.contenu);
        open();
      },

      // handleDelete: async (id) => await DeleteAction(remove, id),
      toggleAll: toggleAllFiche,
      toggleRow: toggleRowFiche,
      selection: selectionFiche,
      check: true,

      otherAction: (id, item) => (
        <>
          <ActionIcon onClick={() => navigate(`coach/${id}`)}>
            <UserPlus size={18} />
          </ActionIcon>
        </>
      ),
    },
  });

  return (
    <Box>
      <Text size={20}>La liste des fiches</Text>
      <Group position="apart" mt={10}>
        <TextInput
          placeholder={"Rechercher une fiche"}
          onChange={(value) => setQuery(value.target.value.toLowerCase())}
          value={query}
          style={{ flex: 1 }}
        />
        <Button onClick={() => {}} component={Link} to={"ajout"}>
          Ajouter une fiche
        </Button>
      </Group>
      <Divider my={"2rem"} />
      {selectionFiche.length > 0 ? (
        <Button
          onClick={onpenDrawer}
          mb={15}
          size="sm"
          loading={isAssignLoading}
        >
          Affecter la fiche a des coachs
        </Button>
      ) : null}

      {/* Fiche tble */}
      <FicheTable />

      <Drawer
        opened={openedDrawer}
        onClose={closeDrawer}
        title={
          <Group position="apart">
            <TextInput
              placeholder={"Rechercher un coach"}
              onChange={(value) =>
                setQueryCoach(value.target.value.toLowerCase())
              }
              value={queryCoach}
            />
            {selectionCoach.length > 0 ? (
              <Button
                disabled={isAssignLoading}
                size="sm"
                onClick={handleAssignFiche}
              >
                Assigner
              </Button>
            ) : null}
          </Group>
        }
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
        position="right"
      >
        <RenderData
          isLoading={isCoachLoading}
          data={
            coachs?.data
              ? coachs?.data.filterData(["fullName"], queryCoach)
              : []
          }
          render={(coach) => (
            <div key={coach?.id}>
              <Group p={10} spacing={30}>
                <Checkbox
                  checked={selectionCoach.includes(coach.id)}
                  onChange={() => toggleRowCoach(coach.id)}
                />
                <Group>
                  <User size={18} />
                  <Text>{coach?.fullName}</Text>
                </Group>
              </Group>

              <Divider />
            </div>
          )}
        />
      </Drawer>

      {/* Edite modal */}
      {/* <FicheEditePage data={currentFiche} opened={opened} onClose={close} /> */}
      <Modal opened={opened} onClose={close} size={"lg"} title={"Modification"}>
        <Box mt={5}>
          {isLoading ? (
            <Center>
              <Loader />
            </Center>
          ) : (
            <form onSubmit={form.onSubmit(handleUpdate)}>
              <Group mb={10}>
                <TextInput
                  placeholder="nom de la fiche"
                  {...form.getInputProps("name")}
                />
                <Select
                  placeholder="selectionner une classe"
                  data={
                    classes
                      ? classes?.data.map((item) => ({
                          value: item?.id,
                          label: item?.name,
                        }))
                      : []
                  }
                  {...form.getInputProps("classeId")}
                  searchable
                  clearable
                />

                <Select
                  placeholder="selectionner un niveauId"
                  data={
                    niveaux
                      ? niveaux?.data.map((item) => ({
                          value: item?.id,
                          label: item?.name,
                        }))
                      : []
                  }
                  {...form.getInputProps("niveaux")}
                  searchable
                  clearable
                />
              </Group>

              <Textarea
                placeholder="Oblectif pedagogique"
                mb={10}
                {...form.getInputProps("objectifPedagogique")}
              />

              <ReactQuill
                theme="snow"
                value={text}
                onChange={(e) => setText(e)}
                modules={configWysiwyg?.modules}
                formats={configWysiwyg?.formats}
                placeholder="Mettre le contenue de la fiche icci"
              />

              <Group mt={10}>
                <Button type="submit" loading={editFicheLoading}>
                  modifier la fiche
                </Button>
                <Button color="gray" onClick={close}>
                  Annuler
                </Button>
              </Group>
            </form>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default FichePage;
