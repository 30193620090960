import { Navigate, Outlet } from "react-router-dom";
import getCurrentUser from "../hooks/getCurrentUser";

const UnProtectedRoutes = () => {
  const user = getCurrentUser();
  return !user ? (
    <Outlet />
  ) : user.status === "PARTENAIRE" ? (
    <Navigate to="/partenaire" replace />
  ) : (
    <Navigate to="/admin" replace />
  );
};

export default UnProtectedRoutes;
