import {
  ActionIcon,
  Alert,
  Anchor,
  Box,
  Button,
  Divider,
  Group,
  MultiSelect,
  Notification,
  NumberInput,
  Paper,
  Popover,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useState } from "react";
import { AlertCircle, Check, Plus, X } from "tabler-icons-react";
import { useAddDiagnosticParentsMutation } from "../../../redux/features/diagnosticParentApi";
import AddAction from "./actions/AddAction";
import { useGetDiagnostiquesQuery } from "../api/features/diagnostiqueApi";
import { useGetNiveauxQuery } from "../../../redux/features/niveauApi";
import AlphabetForm from "./components/AlphabetForm";
import OrganisationForm from "./components/OrganisationForm";
import AudioRecordingForm from "./components/AudioRecordingForm";
import TextSpeachForm from "./components/TextSpeachForm";
import LettreManquanteForm from "./components/LettreManquanteForm";
import AudioRecordingWithTimerForm from "./components/AudioRecordingWithTimerForm";
import FigureForm from "./components/FigureForm";
import { useNavigate } from "react-router-dom";
import TSQ from "./components/TSQ";
import { nanoid } from "@reduxjs/toolkit";
import n2words from "n2words";

function ParentDiagnosticPage() {
  // const { data: classes } = useGetClassesQuery();
  const { data: diagnosticData } = useGetDiagnostiquesQuery();
  // const { data: matieres } = useGetMatiereQuery();
  const { data: niveaux } = useGetNiveauxQuery();

  const navigation = useNavigate();

  const [closed, setClosed] = useState(false);

  function convertWordToNumber(word) {
    const frenchNumbers = Array.from({ length: 100 }, (_, i) => ({
      word: n2words(i, { lang: "fr" }), // Convert number to word
      number: i,
    }));

    const match = frenchNumbers.find((item) => item.word === word);
    return match ? match.number : null; // Return number or null if invalid
  }

  function generateAdjustedChoices(correctNumber) {
    const alternatives = new Set();
    alternatives.add(correctNumber.toString()); // Include the correct number

    while (alternatives.size < 4) {
      const randomVariant =
        correctNumber +
        Math.floor(Math.random() * 10) * (Math.random() > 0.5 ? 1 : -1); // Adjust by a random amount
      alternatives.add(randomVariant.toString());
    }

    return Array.from(alternatives);
  }


  function generateChoices(correctNumber) {
    const alternatives = new Set();
    alternatives.add(correctNumber.toString()); // Include the correct number

    while (alternatives.size < 4) {
      const randomVariant =
        correctNumber +
        Math.floor(Math.random() * 10) * (Math.random() > 0.5 ? 1 : -1); // Generate variations
      alternatives.add(randomVariant.toString());
    }

    return Array.from(alternatives);
  }

  // console.log({ diagnosticData });

  const [add, { isLoading, isSuccess, isError }] =
    useAddDiagnosticParentsMutation();

  const form = useForm({
    initialValues: {
      niveauId: "",
      diagnosticId: "",
      epreuves: [],
      bareme: "",
    },

    validate: {
      epreuves: {
        // type: (value) => (!value ? "Obligatoire" : null),
        // consigne: {
        //   value: (value) => (!value ? "Obligatoire" : null),
        // },
        // correction: (value) => (!value ? "Obligatoire" : null),
        // choices: (value) => (!value ? "Obligatoire" : null),
      },
    },
  });

  const [errors, setErrors] = useState([]);

  // const diagnosticsMath = form.values.epreuves.map(
  //   ({ consigne, choices, type }, _index) =>
  //     type === "operation" ? (
  //       <Box key={_index}>
  //         <TextInput
  //           placeholder="metre la consigne"
  //           mb={10}
  //           radius={10}
  //           rows={1}
  //           {...form.getInputProps(`epreuves.${_index}.titre`)}
  //         />
  //         <Group position="apart">
  //           <Group>
  //             {consigne.map((item, index) =>
  //               item?.type === "n" ? (
  //                 <div
  //                   className=""
  //                   key={index}
  //                   style={{ position: "relative" }}
  //                 >
  //                   <TextInput
  //                     type="number"
  //                     // placeholder="3"
  //                     {...form.getInputProps(
  //                       `epreuves.${_index}.consigne.${index}.value`
  //                     )}
  //                     radius={10}
  //                     w={80}
  //                     placeholder={"nombre"}
  //                   />
  //                   {consigne.length === index + 1 ? (
  //                     <div
  //                       className=""
  //                       style={{
  //                         position: "absolute",
  //                         top: -10,
  //                         right: -5,
  //                         cursor: "pointer",
  //                       }}
  //                       onClick={() =>
  //                         form.removeListItem(
  //                           `epreuves.${_index}.consigne`,
  //                           index
  //                         )
  //                       }
  //                     >
  //                       <X color="red" size={"1.1rem"} />
  //                     </div>
  //                   ) : null}
  //                 </div>
  //               ) : item?.type === "s" ? (
  //                 <div
  //                   className=""
  //                   key={index}
  //                   style={{ position: "relative" }}
  //                 >
  //                   <Select
  //                     placeholder="signe"
  //                     {...form.getInputProps(
  //                       `epreuves.${_index}.consigne.${index}.value`
  //                     )}
  //                     radius={10}
  //                     data={[
  //                       { value: "+", label: "+" },
  //                       { value: "-", label: "-" },
  //                       { value: "*", label: "x" },
  //                       { value: "/", label: "/" },
  //                       { value: "=", label: "=" },
  //                       { value: ">", label: ">" },
  //                       { value: "<", label: "<" },
  //                     ]}
  //                     w={80}
  //                   />
  //                   {consigne.length === index + 1 ? (
  //                     <div
  //                       className=""
  //                       style={{
  //                         position: "absolute",
  //                         top: -10,
  //                         right: -10,
  //                         cursor: "pointer",
  //                       }}
  //                       onClick={() =>
  //                         form.removeListItem(
  //                           `epreuves.${_index}.consigne`,
  //                           index
  //                         )
  //                       }
  //                     >
  //                       <X color="red" />
  //                     </div>
  //                   ) : null}
  //                 </div>
  //               ) : null
  //             )}

  //             <ActionIcon
  //               color="green"
  //               onClick={() => {
  //                 if (consigne.at(-1).type === "n") {
  //                   form.insertListItem(`epreuves.${_index}.consigne`, {
  //                     id: form.values.epreuves[_index].consigne.length + 1,
  //                     value: "",
  //                     type: "s",
  //                   });
  //                 } else {
  //                   form.insertListItem(`epreuves.${_index}.consigne`, {
  //                     id: form.values.epreuves[_index].consigne.length + 1,
  //                     value: "",
  //                     type: "n",
  //                   });
  //                 }
  //               }}
  //             >
  //               <Plus size="1rem" />
  //             </ActionIcon>
  //           </Group>
  //           <Group>
  //             <MultiSelect
  //               radius={10}
  //               {...form.getInputProps(`epreuves.${_index}.correction_dp`)}
  //               data={[
  //                 ...consigne
  //                   .map(({ _, value }) =>
  //                     value
  //                       ? {
  //                           value,
  //                           label: value,
  //                         }
  //                       : null
  //                   )
  //                   .filter((item) => item !== null),
  //               ]}
  //               width={100}
  //               placeholder="Inconnues"
  //             />
  //             <ActionIcon
  //               color="red"
  //               onClick={() => form.removeListItem(`epreuves`, _index)}
  //             >
  //               <X size="1rem" />
  //             </ActionIcon>
  //           </Group>
  //         </Group>

  //         <Box mt={10}>
  //           <Text>Mettre des options pour les choix</Text>
  //           <Group>
  //             {choices.map((item, index) => (
  //               <TextInput
  //                 key={index}
  //                 {...form.getInputProps(`epreuves.${_index}.choices.${index}`)}
  //                 radius={10}
  //                 w={80}
  //                 placeholder={"valeur"}
  //               />
  //             ))}
  //             <ActionIcon
  //               color="green"
  //               onClick={() => {
  //                 form.insertListItem(`epreuves.${_index}.choices`, "");
  //               }}
  //             >
  //               <Plus size="1rem" />
  //             </ActionIcon>
  //           </Group>
  //         </Box>

  //         <Divider my={20} />
  //       </Box>
  //     ) : type === "win" || type === "wil" ? (
  //       <Box key={_index}>
  //         <TextInput
  //           placeholder="metre la consigne"
  //           mb={10}
  //           radius={10}
  //           rows={1}
  //           {...form.getInputProps(`epreuves.${_index}.titre`)}
  //         />

  //         <Group position="apart">
  //           <Group>
  //             {consigne?.map((item, index) => (
  //               <TextInput
  //                 key={index}
  //                 type={type === "wil" ? "text" : "number"}
  //                 // placeholder="3"
  //                 {...form.getInputProps(
  //                   `epreuves.${_index}.consigne.${index}`
  //                 )}
  //                 radius={10}
  //                 w={type === "wil" ? 120 : 80}
  //                 placeholder={
  //                   type === "wil" ? "mettre le nombre en lettre" : "nombre"
  //                 }
  //               />
  //             ))}

  //             <ActionIcon
  //               color="green"
  //               onClick={() => {
  //                 form.insertListItem(`epreuves.${_index}.consigne`, "");
  //               }}
  //             >
  //               <Plus size="1rem" />
  //             </ActionIcon>
  //           </Group>
  //           <Group>
  //             <ActionIcon
  //               color="red"
  //               onClick={() => form.removeListItem(`epreuves`, _index)}
  //             >
  //               <X size="1rem" />
  //             </ActionIcon>
  //           </Group>
  //         </Group>
  //         <Divider my={20} />
  //       </Box>
  //     ) : type === "figure" ? (
  //       <FigureForm
  //         key={_index}
  //         form={form}
  //         index={_index}
  //         consigne={consigne}
  //       />
  //     ) : (
  //       <Box key={_index}>
  //         <TextInput
  //           placeholder="metre la consigne"
  //           mb={10}
  //           radius={10}
  //           rows={1}
  //           {...form.getInputProps(`epreuves.${_index}.titre`)}
  //         />

  //         <Group position="apart">
  //           <Group>
  //             {consigne?.map((item, index) => (
  //               <TextInput
  //                 key={index}
  //                 type={type === "win" ? "text" : "number"}
  //                 // placeholder="3"
  //                 {...form.getInputProps(
  //                   `epreuves.${_index}.consigne.${index}`
  //                 )}
  //                 radius={10}
  //                 w={80}
  //                 placeholder={"nombre"}
  //               />
  //             ))}

  //             <ActionIcon
  //               color="green"
  //               onClick={() => {
  //                 form.insertListItem(`epreuves.${_index}.consigne`, "");
  //               }}
  //             >
  //               <Plus size="1rem" />
  //             </ActionIcon>
  //           </Group>
  //           <Group>
  //             <ActionIcon
  //               color="red"
  //               onClick={() => form.removeListItem(`epreuves`, _index)}
  //             >
  //               <X size="1rem" />
  //             </ActionIcon>
  //           </Group>
  //         </Group>
  //         <Divider my={20} />
  //       </Box>
  //     )
  // );
  const diagnosticsMath = form.values.epreuves.map(
    ({ consigne, choices, type }, _index) =>
      type === "operation" ? (
        <Box key={_index}>
          <TextInput
            placeholder="Mettre la consigne"
            mb={10}
            radius={10}
            {...form.getInputProps(`epreuves.${_index}.titre`)}
          />
          <Group position="apart">
            <Group>
              {consigne.map((item, index) =>
                item?.type === "n" ? (
                  <div
                    key={index}
                    style={{ position: "relative" }}
                  >
                    <TextInput
                      type="number"
                      {...form.getInputProps(`epreuves.${_index}.consigne.${index}.value`)}
                      radius={10}
                      w={80}
                      placeholder="Nombre"
                    />
                    {consigne.length === index + 1 && (
                      <div
                        style={{
                          position: "absolute",
                          top: -10,
                          right: -5,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          form.removeListItem(`epreuves.${_index}.consigne`, index)
                        }
                      >
                        <X color="red" size="1.1rem" />
                      </div>
                    )}
                  </div>
                ) : item?.type === "s" ? (
                  <div
                    key={index}
                    style={{ position: "relative" }}
                  >
                    <Select
                      placeholder="Signe"
                      {...form.getInputProps(`epreuves.${_index}.consigne.${index}.value`)}
                      radius={10}
                      data={[
                        { value: "+", label: "+" },
                        { value: "-", label: "-" },
                        { value: "*", label: "x" },
                        { value: "/", label: "/" },
                        { value: "=", label: "=" },
                        { value: ">", label: ">" },
                        { value: "<", label: "<" },
                      ]}
                      w={80}
                    />
                    {consigne.length === index + 1 && (
                      <div
                        style={{
                          position: "absolute",
                          top: -10,
                          right: -10,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          form.removeListItem(`epreuves.${_index}.consigne`, index)
                        }
                      >
                        <X color="red" />
                      </div>
                    )}
                  </div>
                ) : null
              )}
              <ActionIcon
                color="green"
                onClick={() => {
                  const newType = consigne.at(-1)?.type === "n" ? "s" : "n";
                  form.insertListItem(`epreuves.${_index}.consigne`, {
                    id: form.values.epreuves[_index].consigne.length + 1,
                    value: "",
                    type: newType,
                  });
                }}
              >
                <Plus size="1rem" />
              </ActionIcon>
            </Group>
            <Group>
              <MultiSelect
                radius={10}
                {...form.getInputProps(`epreuves.${_index}.correction_dp`)}
                data={consigne
                  .map(({ _, value }) =>
                    value
                      ? {
                        value,
                        label: value,
                      }
                      : null
                  )
                  .filter((item) => item !== null)}
                width={100}
                placeholder="Inconnues"
              />
              <ActionIcon
                color="red"
                onClick={() => form.removeListItem(`epreuves`, _index)}
              >
                <X size="1rem" />
              </ActionIcon>
            </Group>
          </Group>
          <Box mt={10}>
            <Text>Mettre des options pour les choix</Text>
            <Group>
              {choices.map((item, index) => (
                <TextInput
                  key={index}
                  {...form.getInputProps(`epreuves.${_index}.choices.${index}`)}
                  radius={10}
                  w={80}
                  placeholder="Valeur"
                />
              ))}
              <ActionIcon
                color="green"
                onClick={() =>
                  form.insertListItem(`epreuves.${_index}.choices`, "")
                }
              >
                <Plus size="1rem" />
              </ActionIcon>
            </Group>
          </Box>
          <Divider my={20} />
        </Box>
      ) : type === "win" || type === "wil" ? (
        <Box key={_index}>
          <TextInput
            placeholder="Mettre la consigne"
            mb={10}
            radius={10}
            {...form.getInputProps(`epreuves.${_index}.titre`)}
          />
          <Group position="apart">
            <Group>
              {consigne.map((item, index) => (
                <TextInput
                  key={index}
                  type={type === "wil" ? "text" : "number"}
                  {...form.getInputProps(`epreuves.${_index}.consigne.${index}`)}
                  radius={10}
                  w={type === "wil" ? 120 : 80}
                  placeholder={
                    type === "wil" ? "Nombre en lettres" : "Nombre"
                  }
                />
              ))}
              <ActionIcon
                color="green"
                onClick={() =>
                  form.insertListItem(`epreuves.${_index}.consigne`, "")
                }
              >
                <Plus size="1rem" />
              </ActionIcon>
            </Group>
            <Group>
              <ActionIcon
                color="red"
                onClick={() => form.removeListItem(`epreuves`, _index)}
              >
                <X size="1rem" />
              </ActionIcon>
            </Group>
          </Group>
          <Divider my={20} />
        </Box>
      ) : type === "figure" ? (
        <FigureForm
          key={_index}
          form={form}
          index={_index}
          consigne={consigne}
        />
      ) : type === "missingNumbers" ? (
        <Box key={_index}>
          <TextInput
            placeholder="Complétez la liste en comptant par 5"
            mb={10}
            radius={10}
            {...form.getInputProps(`epreuves.${_index}.titre`)}
          />
          <Group position="apart">
            <Group>
              {consigne.map((item, index) => (
                <TextInput
                  key={index}
                  type="number"
                  placeholder="Nombre"
                  radius={10}
                  w={80}
                  {...form.getInputProps(`epreuves.${_index}.consigne.${index}.value`)}
                />
              ))}
              <ActionIcon
                color="green"
                onClick={() =>
                  form.insertListItem(`epreuves.${_index}.consigne`, {
                    value: "",
                    type: "number",
                  })
                }
              >
                <Plus size="1rem" />
              </ActionIcon>
                  </Group>
                  <ActionIcon
                    color="red"
                    onClick={() => form.removeListItem(`epreuves`, _index)}
                  >
                    <X size="1rem" />
                  </ActionIcon>
          </Group>
          <Divider my={20} />
        </Box>
      ) : type === "numberToWords" ? (
        <Box key={_index}>
          <TextInput
            placeholder="Écrire les nombres en lettres"
            mb={10}
            radius={10}
            {...form.getInputProps(`epreuves.${_index}.titre`)}
          />
          <Group position="apart">
            <Group>
              {consigne.map((subItem, index) => (
                <TextInput
                  key={index}
                  type="number"
                  placeholder="Nombre"
                  radius={10}
                  w={100}
                  {...form.getInputProps(`epreuves.${_index}.consigne.${index}.value`)}
                  onBlur={(e) => {
                    const number = parseInt(e.target.value.trim(), 10);
                    if (!isNaN(number)) {
                      // Ensure the parent object and arrays exist before setting values
                      if (!form.values.epreuves[_index].correction_dp) {
                        form.setFieldValue(`epreuves.${_index}.correction_dp`, []);
                      }

                      form.setFieldValue(
                        `epreuves.${_index}.correction_dp.${index}`,
                        number // Set the correct number in correction
                      );

                      if (!form.values.epreuves[_index].choices) {
                        form.setFieldValue(`epreuves.${_index}.choices`, []);
                      }

                      form.setFieldValue(
                        `epreuves.${_index}.choices`,
                        generateAdjustedChoices(number) // Generate adjusted choices
                      );
                    } else {
                      setErrors([...errors, `Nombre invalide: "${e.target.value}"`]);
                    }
                  }}
                />
              ))}
              <ActionIcon
                color="green"
                onClick={() =>
                  form.insertListItem(`epreuves.${_index}.consigne`, { value: "" })
                }
              >
                <Plus size="1rem" />
              </ActionIcon>
            </Group>
            <Group>
              <ActionIcon
                color="red"
                onClick={() => form.removeListItem(`epreuves`, _index)}
              >
                <X size="1rem" />
              </ActionIcon>
            </Group>
          </Group>
          <Divider my={20} />
        </Box>
      ) : type === "wordToNumbers" ? (
        <Box key={_index}>
          <TextInput
            placeholder="Écrire le mot à convertir en nombre"
            mb={10}
            radius={10}
            {...form.getInputProps(`epreuves.${_index}.titre`)}
          />
          <Group position="apart">
            <Group>
              {consigne.map((subItem, index) => (
                <TextInput
                  key={index}
                  placeholder="Mot en lettres"
                  radius={10}
                  w={200}
                  {...form.getInputProps(`epreuves.${_index}.consigne.${index}.value`)}
                  onBlur={(e) => {
                    const word = e.target.value.trim().toLowerCase();
                    const number = convertWordToNumber(word); // Convert word to number
                    if (number !== null) {
                      form.setFieldValue(
                        `epreuves.${_index}.correction_dp.${index}`,
                        number // Store the number in correction
                      );
                    } else {
                      setErrors([...errors, `Mot invalide: "${word}"`]);
                    }
                  }}
                />
              ))}
              <ActionIcon
                color="green"
                onClick={() =>
                  form.insertListItem(`epreuves.${_index}.consigne`, { value: "" })
                }
              >
                <Plus size="1rem" />
              </ActionIcon>
            </Group>
            <Group>
              <ActionIcon
                color="red"
                onClick={() => form.removeListItem(`epreuves`, _index)}
              >
                <X size="1rem" />
              </ActionIcon>
            </Group>
          </Group>
          <Divider my={20} />
        </Box>
      ) : type === "numberDictation" ? (
        <Box key={_index}>
          <TextInput
            placeholder="Entrez un titre pour l'exercice"
            mb={10}
            radius={10}
            {...form.getInputProps(`epreuves.${_index}.titre`)}
          />
          <Group position="apart">
            <Group>
              {consigne?.map((subItem, index) => (
                <TextInput
                  key={index}
                  placeholder="Nombre dicté"
                  radius={10}
                  w={200}
                  {...form.getInputProps(`epreuves.${_index}.consigne.${index}.value`)}
                  onBlur={(e) => {
                    const number = parseInt(e.target.value.trim(), 10);
                    if (!isNaN(number)) {
                      // Ensure correction_dp and choices arrays are initialized
                      if (!form.values.epreuves[_index].correction_dp) {
                        form.setFieldValue(`epreuves.${_index}.correction_dp`, []);
                      }
                      if (!form.values.epreuves[_index].choices) {
                        form.setFieldValue(`epreuves.${_index}.choices`, []);
                      }

                      // Set the correct number and generate choices
                      form.setFieldValue(`epreuves.${_index}.correction_dp.${index}`, number);
                      form.setFieldValue(`epreuves.${_index}.choices.${index}`, generateAdjustedChoices(number));
                    } else {
                      setErrors((prevErrors) => [
                        ...prevErrors,
                        `Nombre invalide à l'index ${index}: "${e.target.value.trim()}"`,
                      ]);
                    }
                  }}
                />

              ))}
              <ActionIcon
                color="green"
                onClick={() =>
                  form.insertListItem(`epreuves.${_index}.consigne`, { value: "" })
                }
              >
                <Plus size="1rem" />
              </ActionIcon>
            </Group>
            <Group>
              {form.values.epreuves[_index]?.choices?.map((choiceSet, index) => (
                <MultiSelect
                  key={index}
                  radius={10}
                  data={choiceSet || []}
                  {...form.getInputProps(`epreuves.${_index}.choices.${index}`)}
                  placeholder="Choisissez une réponse"
                  w={200}
                />
              ))}
              <ActionIcon
                color="red"
                onClick={() => form.removeListItem(`epreuves`, _index)}
              >
                <X size="1rem" />
              </ActionIcon>
            </Group>
          </Group>
          <Divider my={20} />
        </Box>
      ) : (
        <Box key={_index}>
          <TextInput
            placeholder="Mettre la consigne"
            mb={10}
            radius={10}
            {...form.getInputProps(`epreuves.${_index}.titre`)}
          />
          <Group position="apart">
            <Group>
              {consigne.map((item, index) => (
                <TextInput
                  key={index}
                  type="number"
                  {...form.getInputProps(`epreuves.${_index}.consigne.${index}`)}
                  radius={10}
                  w={80}
                  placeholder="Nombre"
                />
              ))}
              <ActionIcon
                color="green"
                onClick={() =>
                  form.insertListItem(`epreuves.${_index}.consigne`, "")
                }
              >
                <Plus size="1rem" />
              </ActionIcon>
            </Group>
            <Group>
              <ActionIcon
                color="red"
                onClick={() => form.removeListItem(`epreuves`, _index)}
              >
                <X size="1rem" />
              </ActionIcon>
            </Group>
          </Group>
          <Divider my={20} />
        </Box>
      )
  );

  // useEffect(() => {
  //   console.log({ epreuve: form.values.epreuves });
  // }, [form.values.epreuves]);

  /**
   *
   */
  const diagnosticsLecture = form.values.epreuves.map(
    ({ consigne, choices, type, correction_dp, id }, _index) =>
      type === "alphabet" ? (
        <AlphabetForm
          key={_index}
          index={_index}
          form={form}
          correction_dp={correction_dp}
        />
      ) : type === "tsq" ? (
        <TSQ
          key={_index}
          index={_index}
          form={form}
          correction_dp={correction_dp}
        />
      ) : type === "transcription" ? (
        <AudioRecordingForm form={form} key={_index} index={_index} />
      ) : type === "dictee" ? (
        <TextSpeachForm key={_index} form={form} index={_index} />
      ) : type === "lettreManquante" ? (
        <LettreManquanteForm
          key={_index}
          form={form}
          index={_index}
          id={id}
          choices={choices}
          correction_dp={correction_dp}
        />
      ) : type === "dicteeChronometree" ? (
        <AudioRecordingWithTimerForm key={_index} form={form} index={_index} />
      ) : type === "figure" ? (
        <FigureForm
          key={_index}
          form={form}
          index={_index}
          consigne={consigne}
        />
      ) : (
        <OrganisationForm
          key={_index}
          form={form}
          index={_index}
          id={id}
          choices={choices}
          correction_dp={correction_dp}
        />
      )
  );

  /**
   *
   */
  // const handleSubmitMath = form.onSubmit(async (values) => {
  //   if (!values?.bareme) {
  //     return alert("Il faudra mettre le bareme");
  //   }

  //   let isError = false;
  //   const reformdata = values.epreuves.map((item) => {
  //     if (item.type === "operation") {
  //       let operations = [];
  //       item.consigne.map((item) => operations.push(item.value));
  //       return {
  //         ...item,
  //         consigne: item.consigne?.map((item) => {
  //           const valide = eval(operations.join("").split("=")[0]);
  //           // console.log({ valide });
  //           if (
  //             valide ||
  //             valide === parseInt(operations.join("").split("=")[1])
  //           ) {
  //             return { ...item };
  //           } else {
  //             setErrors([...errors, operations.join("")]);
  //             return (isError = true);
  //           }
  //         }),
  //       };

  //     } else if (item.type === "missingNumbers") {
  //       return {
  //         ...item,
  //         consigne: item.consigne?.map((subItem) => {
  //           // Convertir les valeurs en chaîne pour l'API sans faire de calculs
  //           return {
  //             ...subItem,
  //             value: String(subItem.value),  // Assurez-vous que la valeur est une chaîne
  //           };
  //         }),
  //       };
  //     } else if (item.type === "oc" || item.type === "od") {
  //       return {
  //         ...item,
  //         correction_dp: item?.consigne.sort((a, b) => a - b),
  //       };
  //     } else if (item.type === "figure" || item.type === "win" || item.type === "wil") {
  //       return {
  //         ...item,
  //         correction_dp: item?.consigne,
  //       };
  //     } else {
  //       return { ...item };
  //     }
  //   });


  //   if (isError) {
  //     return;
  //   }

  //   try {
  //     // Formater les données avant l'envoi
  //     const newData = {
  //       ...values,
  //       epreuves: reformdata.map((item) =>
  //         item.type === "operation" || item.type === "missingNumbers"
  //           ? {
  //               ...item,
  //               consigne: item?.consigne.map((subItem) => ({
  //                 type: subItem?.type,
  //                 value: String(subItem?.value),  // S'assurer que la valeur est toujours une chaîne
  //               })),
  //             }
  //           : item
  //       ),
  //     };

  //     await AddAction(add, newData);  // Envoi des données formatées
  //     navigation(-1);
  //     setClosed(true);
  //     form.reset();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // });

  const handleSubmitMath = form.onSubmit(async (values) => {
    // console.log(values);

    if (!values?.bareme) {
      return alert("Il faudra mettre le bareme");
    }

    let isError = false;
    
    const reformdata = values.epreuves.map((item) => {
      if (item.type === "operation") {
        let operations = [];
        item.consigne.map((item) => operations.push(item.value));
        return {
          ...item,
          consigne: item.consigne?.map((item) => {
            const valide = eval(operations.join("").split("=")[0]);
            // console.log({ valide });
            if (
              valide ||
              valide === parseInt(operations.join("").split("=")[1])
            ) {
              return { ...item };
            } else {
              setErrors([...errors, operations.join("")]);
              return (isError = true);
            }
          }),
        };
      }
      else if (item.type === "missingNumbers") {
        
        /**
          Epreuves: Complétez la liste en comptant par 5, logic
          Il s'agit d'une epreuve qui consiste a compter par 5. on peut sauter autant de nombre qu'on veux.
          Voici comment on a mis en place la logique de ce type d'epreuve
          
          1- Verifier les bornes (premier et dernier nombre du tableau sont non null): Cette verification nous permet de nous assurer que les valeurs de debut et de fin ne sont pas vides
          2- Meme si les inputs sont de type number, on controle quand meme si c'est des nombres valides
          3- On s'assure que la valeur de debut est inferieur a la valeur de fin
          4- On fait la difference entre les deux bornes pour nous assurer qu'on y inserer d'autres nombres
          5- Pour chaque nombre saisi(input non vide), on verifie s'il est divisible par 5
          6- Entre les nombres saisis, on verifie s'il y a un interval de 5 entre eux
          7- On genere la correction
         */
  
        if (!item.consigne || !Array.isArray(item.consigne)) {
          // Erreur pour les devs
          throw new Error("Données invalides : la consigne doit être un tableau.");
        }
        
        // Verifier les bornes (premier et dernier nombre du tableau sont non null)
        const startIndex = item.consigne.findIndex(val => val !== null);
        const endIndex = item.consigne.length - 1 - item.consigne.slice().reverse().findIndex(val => val !== null);

        if (startIndex === -1 || endIndex === -1) {
          // Message pour l'utilisateur
          return alert("Les bornes de début ou de fin sont absentes.");
        }

        // Valeur de debut du tableau
        const startValue = Number(item.consigne[startIndex].value);
        // Valeur de fin du tableau
        const endValue = Number(item.consigne[endIndex].value);
        
        if (startValue === 0 || endValue === 0) {
          return alert("Les bornes doivent être vides ou égales a 0");
        }

        if (isNaN(startValue) || isNaN(endValue)) {
          return alert("Les bornes doivent être des nombres valides.");
        }

        if (startValue >= endValue) {
          return alert("La borne de fin doit être supérieure à la borne de début.");
        }

        if ((endValue - startValue) % 5 !== 0) {
          return alert("Les bornes ne permettent pas de compter par 5 correctement.");
        }

        // Verification des valeurs saisies
        for (let i = 0; i < item.consigne.length; i++) {
          const consigneItem = item.consigne[i];          
          if (consigneItem.value !== "") {
            if (isNaN(+consigneItem.value)) {
              return alert(`La valeur à la position ${i+1} n'est pas un nombre valide.`);
            }
            if (Number(consigneItem.value) % 5 !== 0) {
              return alert(`Le nombre ${consigneItem.value} à la position ${i+1} n'est pas divisible par 5.`);
            }
          }
        }
        
        // Generation de la correction complete
        const correction = [];
        for (let i = startValue; i <= endValue; i += 5) {
          correction.push(i);
        }

        // Verification des correspondances avec la correction
        for (let i = 0; i < item.consigne.length; i++) {
          const consigneItem = item.consigne[i];
          if (consigneItem.value !== "" && +consigneItem.value !== correction[i - startIndex]) {
            return alert(`La valeur ${consigneItem.value} à la position ${i+1} ne respecte pas le saut de 5.`);
          }
        }

        return {
          ...item,
          // Sequence complete comme correction
          correction_dp: correction,
        };
      } else if (item.type === "numberToWords") {
        const reformattedConsigne = item.consigne.map((subItem, index) => {
          const expectedWord = n2words(parseInt(subItem.value, 10), { lang: "fr" });
          // console.log({subItem, expectedWord, item});
          
          // if (expectedWord !== item.correction_dp[index]) {
          //   setErrors([...errors, `Erreur: attendu ${expectedWord}, reçu ${subItem.value}`]);
          //   isError = true;
          // }
          return subItem;
        });

        return {
          ...item,
          consigne: reformattedConsigne,
          correction_dp: reformattedConsigne.map((subItem) =>
            n2words(parseInt(subItem.value, 10), { lang: "fr" })
          ),
        };
      }
      else if (item.type === "wordToNumbers") {
        const reformattedConsigne = item.consigne.map((subItem, index) => {
          const word = subItem.value.trim().toLowerCase();
          const expectedNumber = convertWordToNumber(word);

          // if (expectedNumber === null || expectedNumber !== item.correction_dp[index]) {
          //   setErrors([
          //     ...errors,
          //     `Erreur: attendu ${expectedNumber || "valide"}, reçu "${word}"`,
          //   ]);
          //   isError = true;
          // }
          return subItem;
        });

        return {
          ...item,
          consigne: reformattedConsigne,
          correction_dp: reformattedConsigne.map((subItem) =>
            convertWordToNumber(subItem.value.trim().toLowerCase())
          ),
        };
      }
      else if (item.type === "numberDictation") {
        const consigneValue = item.consigne?.[0]?.value.trim();
        const correctNumber = parseInt(consigneValue, 10);

        if (isNaN(correctNumber)) {
          setErrors((prevErrors) => [
            ...prevErrors,
            `Veuillez entrer un nombre valide.`,
          ]);
          isError = true;
        }

        return {
          ...item,
          consigne: [correctNumber.toString()], // List of strings
          correction_dp: [correctNumber.toString()], // Correct answer as a list of strings
          choices: generateChoices(correctNumber).map(String), // Choices as strings
        };
      }

      else if (item.type === "oc") {
        return {
          ...item,
          correction_dp: item?.consigne.toSorted((a, b) => a - b),
        };
      } else if (item.type === "od") {
        return {
          ...item,
          correction_dp: item?.consigne.toSorted((a, b) => b - a),
        };
      } else if (item.type === "figure") {
        return {
          ...item,
          correction_dp: item?.correction_dp,
        };
      } else if (item.type === "win" || item.type === "wil") {
        return {
          ...item,
          correction_dp: item?.consigne,
        };
      } else {
        return { ...item };
      }
    });

    if (isError) {
      // console.log("y a une erreur");
      return;
    }

    try {
      const newData = {
        ...values,
        epreuves: reformdata.map((item) =>
          item.type === "operation" || item.type === "missingNumbers" || item.type === "numberToWords" || item.type === "wordToNumbers"
            ? {
              ...item,
              consigne: item?.consigne.map((item) =>
                JSON.stringify({ type: item?.type, value: item?.value })
              ),
            }
            : item
        ),
      };

      await AddAction(add, newData);
      // console.log({ res });
      navigation(-1);
      setClosed(true);
      form.reset();
    } catch (error) {
      console.log(error);
    }
  });

  /**
   *
   */
  const handleSubmitLecture = form.onSubmit(async (values) => {
    // return console.log({ values });

    if (!values?.bareme) {
      return alert("Il faudra mettre le bareme");
    }


    try {
      // return console.log(newData);
      await AddAction(add, {
        ...values,
        epreuves: values?.epreuves.map((item) => ({
          ...item,
          choices:
            typeof item?.choices !== "number"
              ? [...item?.choices]
              : [item?.choices?.toString()],
          correction_dp:
            item?.type === "alphabet" || item?.type === "lettreManquante"
              ? item?.correction_dp.split("-")
              : item?.type === "figure"
                ? item?.correction_dp
                : item?.type === "tsq"
                  ? [JSON.stringify(item?.correction_dp)]
                  : item?.type === "dicteeChronometree"
                    ? [item?.correction_dp]
                    : item?.correction_dp.split(" "),
        })),
      });
      // console.log({ res });
      navigation(-1);
      setClosed(true);
      form.reset();
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <Box pb={30}>
      {closed && isSuccess ? (
        <Notification
          mb={20}
          color="green"
          title="Ajout d'epreuves"
          icon={<Check size="1.2rem" />}
          onClose={() => setClosed(false)}
        >
          Ajout des epreuves reussi
        </Notification>
      ) : closed && isError ? (
        <Notification
          onClose={() => setClosed(false)}
          mb={20}
          color="red"
          title="Ajout d'epreuves"
          icon={<X size="1.2rem" />}
        >
          Une erreur c'est produite lors de l'ajout des epreuves
        </Notification>
      ) : null}

      <form
        onSubmit={(values) =>
          niveaux?.data.find((item) => item?.id === form?.values?.niveauId)
            ?.matiere?.name === "MATHEMATIQUE"
            ? handleSubmitMath(values)
            : handleSubmitLecture(values)
        }
      >
        <Group>
          <Select
            placeholder="Selectionner"
            label="Diagnostique"
            data={
              diagnosticData?.data.map((item) => ({
                value: item?.id,
                label: item?.title + ` (${item?.type_diagnostique})`,
              })) ?? []
            }
            withAsterisk
            radius={10}
            mb={20}
            {...form.getInputProps("diagnosticId")}
            w={300}
          // onChange={() => form.setFieldValue("niveauId", "")}
          />

          <Select
            placeholder="Selectionner"
            label="Niveaux"
            data={
              niveaux?.data
                ?.filter((niveau) => {
                  const selectedDiagnostic = diagnosticData?.data.find(
                    (diagnostic) => diagnostic.id === form.values.diagnosticId
                  );
                  return selectedDiagnostic
                    ? niveau.matiereId === selectedDiagnostic.matiereId
                    : true; // Show all niveaux if no diagnostic is selected
                })
                .map((niveau) => ({
                  value: niveau.id,
                  label: niveau.name,
                })) ?? []
            }
            withAsterisk
            radius={10}
            mb={20}
            disabled={!form.values.diagnosticId}
            {...form.getInputProps("niveauId")}
          />

          <NumberInput
            withAsterisk
            radius={10}
            mb={20}
            placeholder="0"
            label={"Bareme"}
            {...form.getInputProps("bareme")}
          />
        </Group>

        {errors.length > 0 ? (
          <Alert
            icon={<AlertCircle size="1.1rem" />}
            title="Operation Invalide"
            color="red"
            radius="lg"
            withCloseButton
            mt={20}
            onClose={() => setErrors([])}
          >
            {errors.map((error) => (
              <Text>{error}</Text>
            ))}
          </Alert>
        ) : null}

        {form.values.diagnosticId && form.values.niveauId ? (
          <>
            {niveaux?.data.find((item) => item?.id === form?.values?.niveauId)
              ?.matiere?.name === "MATHEMATIQUE" ? (
              <>
                <Paper p={10} withBorder radius={10} my={20}>
                  <Box>
                    {diagnosticsMath}

                    <Popover position="bottom" withArrow shadow="md">
                      <Popover.Target>
                        <Button radius={10}>Ajouter une epreuve</Button>
                      </Popover.Target>
                      <Popover.Dropdown>
                        <Anchor
                          size="sm"
                          onClick={() =>
                            form.insertListItem("epreuves", {
                              id: form.values.epreuves.length + 1,
                              correction_dp: [],
                              type: "operation",
                              titre: "Trouver l'inconnue",
                              choices: [""],
                              consigne: [
                                { id: 1, type: "n", value: "" },
                                { id: 2, type: "s", value: "" },
                                { id: 3, type: "n", value: "" },
                                { id: 4, type: "s", value: "=" },
                                { id: 5, type: "n", value: "" },
                              ],
                            })
                          }
                        >
                          Operation
                        </Anchor>

                        <Divider my={5} />
                        <Anchor
                          size="sm"
                          onClick={() =>
                            form.insertListItem("epreuves", {
                              id: form.values.epreuves.length + 1,
                              correction_dp: [],
                              type: "missingNumbers", // Nouveau type
                              titre: "Complétez la liste en comptant par 5",
                              consigne: [{ value: "", type: "number" }],
                            })
                          }
                        >
                          Compléte la liste en comptant par 5
                        </Anchor>
                        <Divider my={5} />
                        <Anchor
                          size="sm"
                          onClick={() =>
                            form.insertListItem("epreuves", {
                              id: form.values.epreuves.length + 1,
                              correction_dp: [],
                              type: "numberToWords",
                              titre: "Écrire les nombres en lettres",
                              consigne: [{ value: "", type: "text" }],
                            })
                          }
                        >
                          Écrire les nombres en lettres
                        </Anchor>
                        <Divider my={5} />
                        <Anchor
                          size="sm"
                          onClick={() =>
                            form.insertListItem("epreuves", {
                              id: form.values.epreuves.length + 1,
                              correction_dp: [],
                              type: "wordToNumbers",
                              titre: "Écrire les nombres en chiffres",
                              consigne: [{ value: "", type: "text" }],
                            })
                          }
                        >
                          Écrire les nombres en chiffres
                        </Anchor>
                        <Divider my={5} />
                        <Anchor
                          size="sm"
                          onClick={() =>
                            form.insertListItem("epreuves", {
                              id: form.values.epreuves.length + 1,
                              correction_dp: [], // Initialize correction_dp as an empty array
                              type: "numberDictation",
                              titre: "Dictée des nombres",
                              consigne: [{ value: "" }], // Initialize with an empty consigne object
                              choices: [], // Initialize choices as an empty array
                            })
                          }
                        >
                          Dictée des nombres
                        </Anchor>
                        <Divider my={5} />
                        <Anchor
                          size="sm"
                          onClick={() =>
                            form.insertListItem("epreuves", {
                              id: form.values.epreuves.length + 1,
                              correction_dp: "",
                              type: "figure",
                              titre: "Choisie le cercle",
                              choices: [],
                              consigne: [""],
                            })
                          }
                        >
                          Figure
                        </Anchor>
                        <Divider my={5} />
                        <Anchor
                          size="sm"
                          onClick={() =>
                            form.insertListItem("epreuves", {
                              id: form.values.epreuves.length + 1,
                              correction_dp: [],
                              type: "oc",
                              titre:
                                "Mettre dans l'ordre croissant les nombres",
                              consigne: [""],
                              choices: [""],
                            })
                          }
                        >
                          Ordre croissant
                        </Anchor>
                        <Divider my={5} />
                        <Anchor
                          size="sm"
                          onClick={() =>
                            form.insertListItem("epreuves", {
                              id: form.values.epreuves.length + 1,
                              correction_dp: [],
                              type: "od",
                              titre:
                                "Mettre dans l'ordre decroissant les nombres",
                              consigne: [""],
                              choices: [""],
                            })
                          }
                        >
                          Ordre decroissant
                        </Anchor>

                      </Popover.Dropdown>
                    </Popover>
                  </Box>
                </Paper>
              </>
            ) : (
              <Box radius={10} my={20}>
                {diagnosticsLecture}
                <Popover position="bottom" withArrow shadow="md">
                  <Popover.Target>
                    <Button radius={10}>Ajouter une epreuve</Button>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Anchor
                      size="sm"
                      onClick={() =>
                        form.insertListItem("epreuves", {
                          id: nanoid(),
                          correction_dp: "",
                          type: "organisation",
                          titre: "Reorganiser la phrase",
                          choices: [],
                          consigne: [""],
                        })
                      }
                    >
                      Organisation des mots
                    </Anchor>
                    <Divider my={5} />
                    <Anchor
                      size="sm"
                      onClick={() =>
                        form.insertListItem("epreuves", {
                          id: nanoid(),
                          correction_dp: [
                            {
                              titre: "",
                              id: nanoid(),
                              responses: [
                                { titre: "", isCorrect: false, id: nanoid() },
                              ],
                            },
                          ],
                          type: "tsq",
                          titre: "Lis le texte puis réponds aux questions.",
                          choices: [],
                          consigne: [""],
                        })
                      }
                    >
                      Text suivis de Questions
                    </Anchor>
                    <Divider my={5} />
                    <Anchor
                      size="sm"
                      onClick={() =>
                        form.insertListItem("epreuves", {
                          id: nanoid(),
                          correction_dp: "",
                          type: "figure",
                          titre: "Choisie le cercle",
                          choices: [],
                          consigne: [""],
                        })
                      }
                    >
                      Figure
                    </Anchor>
                    <Divider my={5} />
                    <Anchor
                      size="sm"
                      onClick={() =>
                        form.insertListItem("epreuves", {
                          id: nanoid(),
                          correction_dp:
                            "A-B-C-D-E-F-G-H-I-J-K-L-M-N-O-P-Q-R-S-T-U-V-W-X-Y-Z",
                          type: "alphabet",
                          titre: "Lister les lettres de l'alphabet.",
                          choices: [],
                          consigne: [],
                        })
                      }
                    >
                      Alphabet
                    </Anchor>
                    <Divider my={5} />
                    <Anchor
                      size="sm"
                      onClick={() =>
                        form.insertListItem("epreuves", {
                          id: nanoid(),
                          correction_dp:
                            "A-B-C-D-E-F-G-H-I-J-K-L-M-N-O-P-Q-R-S-T-U-V-W-X-Y-Z",
                          type: "lettreManquante",
                          titre:
                            "Remplir les lettres manquantes de l'alphabet.",
                          choices: [],
                          consigne: [],
                        })
                      }
                    >
                      Lettre manquante
                    </Anchor>
                    <Divider my={5} />
                    <Anchor
                      size="sm"
                      onClick={() =>
                        form.insertListItem("epreuves", {
                          id: nanoid(),
                          correction_dp: "",
                          type: "dictee",
                          titre: "Écrivez les sons que vous entendez.",
                          choices: [],
                          consigne: [],
                        })
                      }
                    >
                      dictee
                    </Anchor>
                    <Divider my={5} />
                    <Anchor
                      size="sm"
                      onClick={() =>
                        form.insertListItem("epreuves", {
                          id: nanoid(),
                          correction_dp: "",
                          type: "dicteeChronometree",
                          titre: "Liser le text que vous voyez en 1 minute.",
                          choices: 1,
                          consigne: [],
                        })
                      }
                    >
                      dictee chronometree
                    </Anchor>
                    <Divider my={5} />
                    <Anchor
                      size="sm"
                      onClick={() =>
                        form.insertListItem("epreuves", {
                          id: nanoid(),
                          correction_dp: "",
                          type: "transcription",
                          titre: "Liser le mot que vous voyez.",
                          choices: [],
                          consigne: [],
                        })
                      }
                    >
                      transcription
                    </Anchor>
                  </Popover.Dropdown>
                </Popover>
              </Box>
            )}
            <Group position="right">
              <Button radius={10} color="gray" onClick={() => form.reset()}>
                Annuler
              </Button>
              <Button radius={10} type="submit" loading={isLoading}>
                Enregistrer les epreuves
              </Button>
            </Group>
          </>
        ) : (
          <Alert icon={<AlertCircle size="1rem" />} color="orange" radius="lg">
            Veillez selection la matiere et la classe
          </Alert>
        )}
      </form>
    </Box>
  );
}

export default ParentDiagnosticPage;
