import React, { useCallback, useEffect, useState } from "react";
import { Group, Divider, Pagination, Select } from "@mantine/core";

import useGetPaginateData from "../../hooks/useGetPaginateData";
import TableWithPagination from "./TableWithPagination";

function searchNestedObject(obj, keys, searchString) {
  const lowerSearchString = searchString.toLowerCase();

  for (const key of keys) {
    const nestedKeys = key.split("."); // Permet de gérer les objets imbriqués

    let currentObj = obj;

    for (const nestedKey of nestedKeys) {
      if (currentObj[nestedKey] && currentObj[nestedKey] !== undefined) {
        currentObj = currentObj[nestedKey];
      } else {
        // Si la propriété n'existe pas, la recherche échoue pour cette clé
        break;
      }
    }

    // Effectuer la recherche sur la valeur extraite de l'objet
    if (
      typeof currentObj === "string" &&
      currentObj.toLowerCase().includes(lowerSearchString)
    ) {
      return true;
    }
  }

  return false;
}

function useTableWithPagination({
  useQuery,
  limit = 10,
  filters = [],
  searchs = [],
  searchValue = "",
  Component,
  componentOptions,
  queryParams,
  filterRequestQuery,
}) {
  const [_page, setPage] = useState(1);
  const [_limit] = useState(10);
  const [filterQuery, setFilterQuery] = useState([]);
  const [filtered, setFiltered] = useState(false);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const { data, isLoading } = useGetPaginateData({
    useGetQuery: useQuery,
    limit: _limit,
    offset: _page,
    filter: filterRequestQuery,
  });

  const calculateData = useCallback(() => {
    const findDefaultValue = (key = "") => {
      const value = filters.find((filter) => filter.key === key);
      return value && !filtered ? value.query : "";
    };

    // Pagination Configuration
    const startIndex = (_page - 1) * limit;
    const endIndex = startIndex + limit;

    setTotalPages(
      data
        ? Math.ceil(
            data?.data
              .filter((item) => {
                return searchs.some((value) => {
                  return searchNestedObject(item, [value], searchValue ?? "");
                });
              })
              .filter((item) =>
                filterQuery.length > 0
                  ? filterQuery.some((value) =>
                      item[value?.key]
                        ?.toString()
                        ?.toLocaleLowerCase()
                        ?.includes(
                          value?.value?.toString().toLocaleLowerCase() ||
                            findDefaultValue(value?.key)
                        )
                    )
                  : true
              ).length / limit
          )
        : 0
    );

    setCurrentPageData(
      data
        ? data?.data
            .filter((item) => {
              return searchs.some((value) => {
                // console.log(searchNestedObject(item, [value], search));
                return searchNestedObject(item, [value], searchValue);
              });
            })
            .filter((item) =>
              filterQuery.length > 0
                ? filterQuery.some((value) =>
                    item[value?.key]
                      ?.toString()
                      ?.toLocaleLowerCase()
                      ?.includes(
                        value?.value?.toString().toLocaleLowerCase() ||
                          findDefaultValue(value?.key)
                      )
                  )
                : true
            )
            .slice(startIndex, endIndex)
        : []
    );
    // console.log("lala ", queryParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_page, _limit, data, searchValue, filterQuery, queryParams]);

  useEffect(() => {
    calculateData();
  }, [calculateData]);

  return {
    TableWithPagination: ({ searchComponent }) => {
      return (
        <>
          {/* My input filter */}
          {/* <Group position="apart" mt={10}>
            {searchComponent ?? (
              // <TextInput
              //   // value={search}
              // onChange={(e) => {
              //   console.log(e.target.value);
              // }}
              // placeholder="Recherche"
              //   w={600}
              // />
              <input
                onChange={(e) => {
                  console.log(e.target.value);
                  setSearch(e.target.value);
                }}
                se
                placeholder="Recherche"
              />
            )}
            {componentOptions?.handleAdd ? (
              <Button title="Ajouter" onClick={componentOptions?.handleAdd}>
                Ajouter
              </Button>
            ) : null}
          </Group> */}

          <Divider my={15} />

          <Group mb={10}>
            {filters.map((filter, index) => (
              <Select
                key={index}
                data={filter?.data ?? []}
                placeholder={filter?.label}
                defaultValue={filter?.query?.toString().toLowerCase() || ""}
                clearable
                value={
                  filterQuery?.find(
                    (item) => item?.key?.toString() === filter?.key?.toString()
                  )?.value ?? ""
                }
                onChange={(e) => {
                  setPage(1);
                  setFiltered(true);
                  setFilterQuery((v) => [
                    ...v.filter((item) => item.key !== filter?.key),
                    {
                      value: e ? e.toString()?.toLocaleLowerCase() : "",
                      key: filter?.key,
                    },
                  ]);
                }}
              />
            ))}
          </Group>

          {/* My table */}
          {Component ? (
            <Component
              data={currentPageData}
              isLoading={isLoading}
              fields={componentOptions.fields}
              navigation={componentOptions.navigation}
              handleEdit={componentOptions.handleEdit}
              handleDelete={componentOptions.handleDelete}
              {...componentOptions}
            />
          ) : (
            <TableWithPagination
              data={currentPageData}
              isLoading={isLoading}
              fields={componentOptions.fields}
              navigation={componentOptions.navigation}
              handleEdit={componentOptions.handleEdit}
              handleDelete={componentOptions.handleDelete}
              {...componentOptions}
            />
          )}
          {/* My pagination */}
          <Group position="apart" my={10}>
            {/* <Select
            data={["5", "10", "15", "20", "25"]}
            onChange={setLimit}
            value={_limit.toString()}
          /> */}
            <div></div>
            <Pagination value={_page} onChange={setPage} total={totalPages} />
          </Group>
        </>
      );
    },
  };
}

export default useTableWithPagination;
