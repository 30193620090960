import { useMemo, useState } from "react";
import {
  Table,
  ScrollArea,
  Group,
  Text,
  ActionIcon,
  Badge,
  Avatar,
  Pagination,
  Loader,
} from "@mantine/core";
import { Calendar, Eye, Trash } from "tabler-icons-react";
import { sortData } from "../../../utils/tableActions";
import Th from "../../../components/Th";
import { useNavigate } from "react-router-dom";

export default function CoachAppelKpi({ data ,loading}) {
  let [limit] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  
  const navigation = useNavigate();
  
  const currentData = data?.data
  
  const startIndex = (currentPage - 1) * limit;
  const endIndex = startIndex + limit;
  const totalPages = Math.ceil(currentData?.length / limit);
  const currentDataPage = currentData?.slice(startIndex, endIndex);

  const rows = currentDataPage?.map((row) => (
    <tr key={row?.id}>
      <td>
        <Group noWrap>
          <Calendar color="gray" size={20} />
          <Text weight={600}>
            {new Date(row?.created_at__date).toLocaleDateString("fr", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            })}
          </Text>
        </Group>
      </td>
      <td>
        <Text size={"sm"}>{row?.total_appels}</Text>
      </td>
      <td>
        <Text size={"sm"}>{row?.pourcentage_present}%</Text>
      </td>
      <td>
        <Group>
        <Text size={"sm"}>{row?.pourcentage_absent}%</Text>
        </Group>
      </td>
      <td>
        <Group>
        <Text size={"sm"}>{row?.pourcentage_retard}%</Text>
        </Group>
      </td>
      {/* <td>
        <Group>
        <ActionIcon
            color="blue"
            onClick={()=>navigation("appels",{
              date:row?.created_at__date,
              coachId:row?.coachId
            })}
          >
            <Eye size={18} />
          </ActionIcon>
        </Group>
      </td> */}
    </tr>
  ));

  return (
    <ScrollArea>
      <Table
        horizontalSpacing="md"
        verticalSpacing="xs"
        miw={700}
        sx={{ tableLayout: "fixed" }}
        bg={"#fff"}
        mb={30}
      >
        <thead>
          <tr>
            <Th
            //   sorted={sortBy === "firstName"}
            //   reversed={reverseSortDirection}
            //   onSort={() => setSorting("firstName")}
            >
              Date
            </Th>
            <Th
            //   sorted={sortBy === "ecole"}
            //   reversed={reverseSortDirection}
            //   onSort={() => setSorting("ecole.name")}
            >
              Total eleves
            </Th>
            <Th
            //   sorted={sortBy === "boursier"}
            //   reversed={reverseSortDirection}
            //   onSort={() => setSorting("boursier")}
            >
              Presents
            </Th>
            <Th
            //   sorted={sortBy === "boursier"}
            //   reversed={reverseSortDirection}
            //   onSort={() => setSorting("boursier")}
            >
              Absents
            </Th>
            <Th
            //   sorted={sortBy === "boursier"}
            //   reversed={reverseSortDirection}
            //   onSort={() => setSorting("boursier")}
            >
              Retards
            </Th>
            {/* <Th>Details</Th> */}
          </tr>
        </thead>
        {
            loading?
            <Loader />
            :
            <tbody>
                {rows?.length > 0 ? (
                    rows
                ) : (
                    <tr>
                    <td>
                        <Text weight={500} align="center">
                        Pas de donnees
                        </Text>
                    </td>
                    </tr>
                )}
            </tbody>
        }
        
      </Table>
      <Group position="right" mt={10}>
            <Pagination
                value={currentPage}
                onChange={setCurrentPage}
                total={totalPages}
            />
        </Group>
    </ScrollArea>
  );
}
