import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

const ficheApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getFiche: build.query({
      query: (id) => API.FICHES.GET(id),
      providesTags: ["Fiches"],
    }),
    getFiches: build.query({
      query: () => API.FICHES.GETALL(),
      providesTags: ["Fiches"],
    }),
    getCoachWithFiches: build.query({
      query: ({ coachId }) => `fiches/coachs/?coach=${coachId}`,
      providesTags: ["Fiches", "Evaluations"],
    }),
    getFicheWithCoachs: build.query({
      query: ({ ficheId }) => `fiches/${ficheId}/coachs/`,
      providesTags: ["Fiches", "Evaluations"],
    }),
    getCoachFiches: build.query({
      query: (ficheId) => API.FICHES.GETALLCOACHSFICHE(ficheId),
      providesTags: ["Fiches"],
    }),
    addFiches: build.mutation({
      query({ body, token }) {
        return {
          url: API.FICHES.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Fiches"],
    }),
    assignFiche: build.mutation({
      query({ body, token }) {
        return {
          url: API.FICHES.ASSIGNATION_FICHE_COACH(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Fiches", "Coachs"],
    }),
    EditFiches: build.mutation({
      query({ body, token }) {
        return {
          url: API.FICHES.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Fiches"],
    }),
    deleteFiches: build.mutation({
      query(id) {
        return {
          url: API.FICHES.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Fiches"],
    }),
    desacteferCoachFiches: build.mutation({
      query({ ficheId, coachId }) {
        return {
          url: API.FICHES.DESACFECTERCOACHFICHE(ficheId, coachId),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Fiches", "Coachs"],
    }),
  }),
});

export const {
  useGetFicheQuery,
  useGetCoachWithFichesQuery,
  useGetFicheWithCoachsQuery,
  useGetFichesQuery,
  useGetCoachFichesQuery,
  useAssignFicheMutation,
  useAddFichesMutation,
  useEditFichesMutation,
  useDeleteFichesMutation,
  useDesacteferCoachFichesMutation,
} = ficheApi;
