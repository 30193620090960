import { useMemo, useState } from "react";
import { Table, ScrollArea, Group, Text, ActionIcon } from "@mantine/core";
import { Edit, Eye, Trash } from "tabler-icons-react";
import { sortData } from "../../../utils/tableActions";
import Th from "../../../components/Th";
import { useNavigate } from "react-router-dom";

export default function EleveTable({ data, handleEdit, handleDelete }) {
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const navigation = useNavigate();

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search: "" }));
  };

  // console.log({ sortedData, data });

  useMemo(() => {
    setSortedData(data);
    // console.log(data);
  }, [data]);

  const rows = sortedData.map((row) => (
    <tr key={row?.id} className={row?.abandoned && "bg-warning"}>
      <td>
        <Group>
          {/* <Avatar color="blue" radius={"50%"}>
            {row?.firstName?.at(0)}
            {row?.lastName?.at(0)}
          </Avatar> */}
          <Text weight={600}>
            {row?.firstName} {row?.lastName}
          </Text>
        </Group>
      </td>
      <td>{row?.classe?.name}</td>
      <td>{row?.school?.name}</td>
      <td>{row?.parent?.fullName}</td>
      <td>{row?.parrainId ? "OUI" : "NON"}</td>
      <td>
        <Group position="right" noWrap>
          <ActionIcon
            color="blue"
            onClick={() =>
              navigation(`${row?.id}`, { state: { classeId: row?.classe?.id } })
            }
          >
            <Eye size={18} />
          </ActionIcon>
          <ActionIcon color="green" onClick={() => handleEdit(row?.id, row)}>
            <Edit size={18} />
          </ActionIcon>
          <ActionIcon onClick={() => handleDelete(`${row?.id}`)}>
            <Trash size={18} color="red" />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  return (
    <ScrollArea>
      <Table
        horizontalSpacing="md"
        verticalSpacing="xs"
        miw={700}
        sx={{ tableLayout: "fixed" }}
        bg={"#fff"}
      >
        <thead>
          <tr>
            <th
            // sorted={sortBy === "firstName"}
            // reversed={reverseSortDirection}
            // onSort={() => setSorting("firstName")}
            >
              Eleves
            </th>
            <th
            // sorted={sortBy === "classe"}
            // reversed={reverseSortDirection}
            // onSort={() => setSorting("classe.name")}
            >
              classes
            </th>
            <th
            // sorted={sortBy === "ecole"}
            // reversed={reverseSortDirection}
            // onSort={() => setSorting("ecole.name")}
            >
              ecoles
            </th>
            <th
            // sorted={sortBy === "ecole"}
            // reversed={reverseSortDirection}
            // onSort={() => setSorting("ecole.name")}
            >
              Parent
            </th>
            <th
            // sorted={sortBy === "boursier"}
            // reversed={reverseSortDirection}
            // onSort={() => setSorting("boursier")}
            >
              boursiers
            </th>
            <th style={{ textAlign: "right" }}>action</th>
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <tr>
              {/* <td colSpan={Object.keys(data[0]).length}> */}
              <td>
                <Text weight={500} align="center">
                  Pas de donnees
                </Text>
              </td>
              {/* </td> */}
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
}
