import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

const maitreApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getMaitres: build.query({
      query: () => API.MAITRE.GETALL(),
      providesTags: ["Maitres"],
    }),
    addMaitres: build.mutation({
      query({ body, token }) {
        return {
          url: API.MAITRE.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Maitres"],
    }),
    EditMaitres: build.mutation({
      query({ body, token }) {
        return {
          url: API.MAITRE.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Maitres"],
    }),
    deleteMaitres: build.mutation({
      query(id) {
        return {
          url: API.MAITRE.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Maitres"],
    }),
  }),
});

export const {
  useGetMaitresQuery,
  useAddMaitresMutation,
  useEditMaitresMutation,
  useDeleteMaitresMutation,
} = maitreApi;
