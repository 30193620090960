import { useCallback, useMemo, useState } from "react";
import {
  Table,
  ScrollArea,
  Group,
  Text,
  ActionIcon,
  Switch,
  TextInput,
  Badge,
} from "@mantine/core";
import { Check, Edit, Eye, Trash } from "tabler-icons-react";
import { sortData } from "../../../utils/tableActions";
import Th from "../../../components/Th";
import { useNavigate } from "react-router-dom";
import { useEditEpreuvesMutation } from "../../../redux/features/epreuveApi";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
// import ModalEditeEpreuve from "./ModalEditeEpreuve";
// import { useNavigate } from "react-router-dom";

export default function EpreuveTable({ data, handleArchive, handleDelete }) {
  const [search, setSearch] = useState("");
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [bareme, setBareme] = useState(0);
  const navigation = useNavigate();
  const [editBaremeEpreuve] = useEditEpreuvesMutation();
  const dispatch = useDispatch();

  const setSorting = useCallback((field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search }));
  }, []);

  useMemo(() => {
    setSortedData(data);
    // console.log(data);
  }, [data]);

  // const handleDelete = useCallback((id) => {
  //   console.log({ id });
  // }, []);

  const rows = sortedData?.map((row) => (
    <tr key={row?.id}>
      <td>
        <Text weight={600}>
          {row?.diagnostic} ({row?.diagnosticId?.type_diagnostique})
        </Text>
        {/* {console.log({ row })} */}
      </td>
      <td colSpan={3}>
        <Group noWrap>
          {/* <Badge size="sm">{row?.title}</Badge> */}
          {/* <Avatar color="blue" radius={"xl"}>
            <FileText />
          </Avatar> */}
          <Text weight={600} style={{}}>
            {row?.titre}
          </Text>
        </Group>
      </td>
      <td className="flex flex-nowrap">
        {/* <Text weight={600}>{row?.bareme}</Text> */}
        {editItem && editItem === row.id ? (
          <TextInput
            size="xs"
            radius={20}
            w={70}
            type="number"
            value={bareme}
            onChange={(e) => setBareme(e.target.value)}
          />
        ) : (
          <Badge>{row?.bareme}</Badge>
        )}

        <Group>
          {editItem && editItem === row.id ? (
            <ActionIcon
              color="green"
              onClick={async () => {
                try {
                  const body = {
                    id: row.id,
                    bareme: bareme,
                  };
                  const res = await editBaremeEpreuve({ body });
                  toast.success("Modification bareme reussie");
                  console.log("response edit", res);
                } catch (error) {
                  toast.error("edit bareme erreur");
                  console.log("edit bareme erreur ", error);
                }
              }}
            >
              <Check size={18} />
            </ActionIcon>
          ) : (
            <ActionIcon
              color="green"
              onClick={() => {
                console.log("clicked edit");
                setEditItem(row?.id);
                setBareme(parseInt(row.bareme));
              }}
            >
              <Edit size={18} />
            </ActionIcon>
          )}
          {/* <ActionIcon
                    color="red"
                    onClick={async () => {
                      await DeleteAction(deleteDiagnosticsNiveau, item?.id);
                      setEditItem(null);
                    }}
                    loading={item.id === editItem && deleteLoading}
                  >
                    <Trash size={18} />
                  </ActionIcon> */}
        </Group>
      </td>
      {/* <td>{row?.sujet ? <Badge>....</Badge> : "pas de sujet"}</td>
      <td>{row?.correction ? <Badge>....</Badge> : "pas de correction"}</td> */}
      <td>
        <Switch
          style={{ cursor: "pointer" }}
          checked={row?.archived}
          onChange={() => handleArchive(row.id, row.archived)}
        />
      </td>
      <td>
        <Group position="right">
          <ActionIcon
            color="blue"
            onClick={() =>
              navigation(`details epreuve`, {
                state: { title: row.title, id: row.id },
              })
            }
          >
            <Eye size={18} />
          </ActionIcon>
          {/* <ModalEditeEpreuve item={row} /> */}
          <ActionIcon onClick={() => handleDelete(row.id)}>
            <Trash size={18} color="red" />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  return (
    <ScrollArea>
      <Table
        horizontalSpacing="md"
        verticalSpacing="xs"
        miw={700}
        sx={{ tableLayout: "fixed" }}
        bg={"#fff"}
        mb={30}
      >
        <thead>
          <tr>
            <Th
              sorted={sortBy === "Matieres"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("Matieres")}
            >
              Diagnostic
            </Th>
            <Th
              sorted={sortBy === "firstName"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("firstName")}
            >
              Titre
            </Th>
            <th></th>
            <th></th>
            <Th
              sorted={sortBy === "Matieres"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("Matieres")}
            >
              Bareme
            </Th>
            {/* <Th
              sorted={sortBy === "Matieres"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("Matieres")}
            >
              Sujet
            </Th>
            <Th
              sorted={sortBy === "Matieres"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("Matieres")}
            >
              Correction
            </Th> */}
            <Th
              sorted={sortBy === "nombreDeNiveaux"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("nombreDeNiveaux")}
            >
              Archive
            </Th>
            <th style={{ textAlign: "right" }}>action</th>
          </tr>
        </thead>
        <tbody>
          {rows?.length > 0 ? (
            rows
          ) : (
            <tr>
              {/* <td colSpan={Object.keys(data[0]).length}> */}
              <Text weight={500} align="center">
                Pas de donnees
              </Text>
              {/* </td> */}
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
}
