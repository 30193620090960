import React from "react";

function Box(
  {
    children,
    style,
    onClick,
    h,
    w,
    flex,
    bg,
    p,
    m,
    mt,
    mb,
    my,
    pt,
    pb,
    py,
    mx,
    mr,
    ml,
    px,
    pl,
    pr,
    radius,
    wihtBorder,
  },
  props
) {
  return (
    <div
      style={{
        ...style,
        flex: flex,
        width: w,
        height: h,
        backgroundColor: bg,
        padding: p,
        margin: m,
        marginTop: mt ?? my,
        marginBottom: mb ?? my,
        marginLeft: ml ?? mx,
        marginRight: mr ?? mx,
        paddingTop: pt ?? py,
        paddingBottom: pb ?? py,
        paddingLeft: pl ?? px,
        paddingRight: pr ?? px,
        borderRadius: radius ?? 5,
        border: wihtBorder && "1px solid rgba(0,0,0,0.1)",
      }}
      {...props}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default Box;
